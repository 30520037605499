const local_storage_name = process.env.REACT_APP_LOCALSTORAGE_NAME;

const initialState = {
  userId: "",
  token: localStorage.getItem(local_storage_name) || "",
  userName: "",
  imagePath: "",
  userType: "",
  selectedTypeId: "",
  followid: "",
  followUserType: "",
  followProfileId: "",
  revId: "",
  phNumber: "",
  newReviewCount: "",
  newInboxCount: "",
  groupNotify: "",
  newMentionCount: "",
  id: "",
  emailVerified: "",
  phoneVerified: "",
  // userTypeInfo: "",
  userTypeInfo: [],
  email: "",
  phone: "",
  profileId: "",
  // busnAdminId: "",
  busnAdminId: null,
  isKycVerified: ""
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_USER_DATA":
      if (action.payload.token) {
        localStorage.setItem(local_storage_name, action.payload.token);
      }
      // Safely accessing userTypeInfo and ensuring it's an array
      const userTypeInfo = Array.isArray(action.payload.userTypeInfo) ? action.payload.userTypeInfo : [];

      // Find business admin ID safely
      const busnAdmin = userTypeInfo.find(
        (userType) =>
          userType.userTypeName === "Business" &&
          userType.businessId === action.payload.profileId
      );

      return {
        ...state,
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
        imagePath: action.payload.imagePath,
        userType: action.payload.userType,
        newReviewCount: action.payload.newReviewCount,
        newInboxCount: action.payload.newInboxCount,
        groupNotify: action.payload.groupNotify,
        newMentionCount: action.payload.newMentionCount,
        emailVerified: action.payload.emailVerified,
        phoneVerified: action.payload.phoneVerified,
        // userTypeInfo: action.payload.userTypeInfo,
        userTypeInfo: userTypeInfo,
        email: action.payload.email,
        // phone: action.payload.phone != undefined ? action.payload.phone : "",
        phone: action.payload.phone || "",
        profileId: action.payload.profileId,
        isKycVerified: action.payload.isKycVerified,
        // busnAdminId:
        //   (
        //     action.payload.userTypeInfo.find(
        //       (userType) =>
        //         userType.userTypeName === "Business" &&
        //         userType.businessId === action.payload.profileId
        //     ) || {}
        //   ).busnAdminId || null,
        busnAdminId: busnAdmin ? busnAdmin.busnAdminId : null,
      };
    case "LOGOUT":
      localStorage.removeItem(local_storage_name);
      localStorage.removeItem("userType");
      localStorage.removeItem("switchAccount");
      localStorage.removeItem("profileId");
      return { ...initialState };
    case "USER_TYPE_ID":
      return {
        ...state,
        selectedTypeId: action.payload.selectedTypeId,
      };
    case "FOLLOW_ID":
      return {
        ...state,
        followid: action.payload.followid,
        followUserType: action.payload.userType,
        followProfileId: action.payload.profileId,
      };
    case "REV_ID":
      return {
        ...state,
        revId: action.payload.revId,
      };
    case "PH_NUMBER":
      return {
        ...state,
        phNumber: action.payload.phNumber,
      };
    case "NOT_REV_INFO":
      return { ...state, id: action.payload.id };
    default:
      return state;
  }
};

export default userReducer;
