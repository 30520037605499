import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import "../../../assets/css/PostCard.css";
import { LuThumbsUp } from "react-icons/lu";
import { FaRegComment } from "react-icons/fa6";
import like_icn from "../../../assets/images/like_icon.svg";
import { HiThumbUp } from "react-icons/hi";
import CustomModal from "../../plugins/CustomModal";
import profile from "../../../assets/images/profile.jpg";
import { connect } from 'react-redux';
import moment from "moment";
import { useToast } from "../../Toast";
import { GoDotFill } from "react-icons/go";
import StarRating from "../DashboardTabs/StarRating";
import chatImg from "../../../assets/images/dash_chat.png";
import { useNavigate } from "react-router-dom";
import RatingBar from "../../plugins/RatingBar";
import { BiSolidCommentDetail } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { MdReviews } from "react-icons/md";
import { useDispatch } from "react-redux";
import { followId } from "../../../Redux/Actions/userAction";
import { BsGridFill } from "react-icons/bs";
const PostCard = (props) => {
  const [visibleImages, setVisibleImages] = useState(4);
  const [showModal, setShowModal] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);
  const [addCmtModal, setAddCmtModal] = useState(false);
  const [commentTxt, setCommentTxt] = useState("");
  const [cmtTxtError, setCmtTxtError] = useState("");
  const [toolTipText, setToolTipText] = useState("");
  const [currentIndex, setcurrentIndex] = useState(null);
  const [postReplies, setPostReplies] = useState([]);
  const [childcurrentIndex, setchildcurrentIndex] = useState(null);
  const [childreplycurrentIndex, setchildreplycurrentIndex] = useState([]);
  const [showChildReply, setShowChildReply] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const handleMouseEnter = (data) => {
  //   setToolTipText(data);
  // };
  const togglecomment = (id) => {
    setShowChildReply(false);
    setchildcurrentIndex(null);
    if (currentIndex == id) {
      setcurrentIndex(null);
    } else {
      setcurrentIndex(id);
    }
  };
  const handlerplylikechange = (id, postId, parentCommentId) => {
    console.log("parentCommentId", parentCommentId);
    updaterplyLikeStatus({
      postId: postId,
      commentId: id,
      userId: props.userId,
      liked: 1,
      parentCommentId: parentCommentId,
    });
  };
  const handlerplydislikechange = (id, postId, parentCommentId) => {
    console.log("parentCommentId", parentCommentId);
    updaterplyLikeStatus({
      postId: postId,
      commentId: id,
      userId: props.userId,
      liked: 0,
      parentCommentId: parentCommentId,
    });
  };
  const rplylikecntDecrement = (id, postId, parentCommentId) => {
    console.log("parentCommentId", parentCommentId);
    updaterplyLikeStatus({
      postId: postId,
      commentId: id,
      userId: props.userId,
      liked: -1,
      parentCommentId: parentCommentId,
    });
  };
  const updaterplyLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addPostCommentLike(likeParams);
      if (response.data.status == "OK") {
        props.loadPostList();
        if (likeParams.parentCommentId != null)
          loadPostreplies(likeParams.postId, likeParams.parentCommentId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const togglechildcomment = (id) => {
    if (childreplycurrentIndex == id) {
      setchildreplycurrentIndex(null);
    } else {
      setchildreplycurrentIndex(id);
    }
  };
  const viewChildComments = (postId, id) => {
    setcurrentIndex(null);
    setShowChildReply(!showChildReply);
    if (childcurrentIndex == id) {
      setchildcurrentIndex(null);
    } else {
      setchildcurrentIndex(id);
    }
    loadPostreplies(postId, id);
  };
  const loadPostreplies = async (postId, commentId) => {
    const params = {
      postId: postId,
      parentCommentId: commentId,
    };
    try {
      const response = await window.apiService.getPostCommentReplies(params);
      if (response.data.status == "OK") {
        setPostReplies(response.data.data);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const showMoreImages = () => setShowModal(true);
  const toast = useToast();
  const determineGrid = () => {
    if (props.postInfo.attachments.length === 1) {
      return "one-image";
    } else return "four-images";
  };
  const handleAddComment = () => {
    setAddCmtModal(true);
    setCmtTxtError("");
  };
  const addPostLike = async (postId, userId, isLiked) => {
    const params = {
      userId: userId,
      postId: postId,
      liked: isLiked === 1 ? -1 : 1,
    };
    try {
      const response = await window.apiService.addPostLike(params);
      if (response.data.status == "OK") {
        props.loadPostList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postComment = async (id, type) => {
    if (commentTxt.trim().length !== 0) {
      const params = {
        userId: props.userId,
        commentText: commentTxt,
        postId: props.postInfo.postId,
        parentCommentId: type == "child" ? id : null,
      };
      try {
        const response = await window.apiService.addPostComment(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setCommentTxt("");
          setAddCmtModal(false);
          setcurrentIndex(null);
          setchildcurrentIndex(null);
          setchildreplycurrentIndex(null);
          setShowChildReply(false);
          props.loadPostList();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setCmtTxtError("Comment text is required");
    }
  };
  const renderFile = (filePath) => {
    if (filePath.match(/\.(jpeg|jpg|png|gif|bmp|tiff)$/i)) {
      return (
        <img
          src={filePath ? filePath : profile}
          alt="Image"
          className="post-image"
        />
      );
    }

    if (filePath.match(/\.(mp4|avi|mov|wmv|flv|mkv)$/i)) {
      return (
        <video controls width="100%">
          <source src={filePath} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return null;
  };
  const handleNameClick = (userId, userType, TypeId) => {
    dispatch(followId(userId, userType, TypeId));
    navigate("/dashboard/viewuser", {
      state: { userId: userId, userType: userType, profileId: TypeId, tiiptok: true },
    });
  };
  const handleReviewClick = (
    userId,
    professionalList,
    professionalId,
    businessId,
    businessName,
    viewUserType,
    professionType,
    professionTypeId
  ) => {
    navigate("/dashboard/viewuser/postreview", {
      state: {
        userId,
        professionalList,
        professionalId,
        businessId,
        businessName,
        viewUserType,
        professionType,
        professionTypeId,
      },
    });
  };
  const viewuser = (e) => {
    e.preventDefault();
    navigate("/dashboard/viewuser", {
      state: {
        userId: props.postInfo.userId,
        userType: props.postInfo.businessId ? "Business" : props.userType,
        profileId: props.postInfo.businessId ? props.postInfo.businessId : null,
      },
    });

  };

  return (
    <div className="post" style={{ marginTop: "5px" }}>
      <div className="row align-items-center m-2">
        <Col sm={12} md={6} lg={8} className="p-0">
          <div className="d-flex align-items-center">
            <span className="tooltip">
              <span className="c_pointer d-flex align-items-center">
                <img
                  src={props.postInfo.imagePath}
                  alt=""
                  className="post-profile-pic"
                />
                <div className="ml-2">
                  <div className="d-flex align-items-center">
                    <h5
                      className="mb-0 fz_15 mr-2"
                      title={props.postInfo.userName}
                      onClick={viewuser}
                    >
                      {props.postInfo.userName.length > 15
                        ? `${props.postInfo.userName.slice(0, 15)}...`
                        : props.postInfo.userName}
                    </h5>
                    {props.userId !== props.postInfo.postedBy ? (
                      props.postInfo.isFollow ? (
                        <p className="mb-0 ml-3 text-red fz_15 font-weight-500">Unfollow</p>
                      ) : (
                        <p className="mb-0 ml-3 f13_clr fz_15 font-weight-500">Follow</p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <StarRating

                    rating={props.postInfo.averageReviewCount}
                    disabled={true}
                    customClass="user-star"
                    lineH="lineH"
                  />
                </div>
              </span>

              <div className="tooltiptext post_tooltip">
                <div>
                  <Row className="pt-1 pb-1">
                    <Col sm={12} md={3} lg={3} className="p-0">
                      <img
                        className="post-prfe-pic"
                        src={
                          props.postInfo.imagePath
                            ? props.postInfo.imagePath
                            : profile
                        }
                        alt="img"
                        width={70}
                        height={70}
                      />
                    </Col>
                    <Col sm={12} md={8} lg={7} className="p-0 text-left">
                      <Row className="d-flex">
                        {/* <Col sm={8} md={10} lg={10}> */}
                        <h6 className="mb-0">{props.postInfo.userName}</h6>
                        {/* </Col> */}
                        {/* <Col sm={4} md={2} lg={2} className="p-0 text-left"> */}</Row>
                      <Row className="d-flex mt-2">
                        {props.userId !== props.postInfo.postedBy && (
                          <div className="d-flex "><MdReviews
                            title="Add Review"
                            className="rev_clr fz-22 mr-2 c_pointer"
                            onClick={() =>
                              handleReviewClick(
                                props.postInfo.postedBy,
                                undefined,
                                undefined,
                                props.postInfo.businessId,
                                props.postInfo.userName,
                                props.userType,
                                undefined,
                                undefined
                              )
                            }
                          />
                            <div
                              className="mr-2 c_pointer"
                              onClick={() => {
                                navigate("/inbox/3", {
                                  state: { userId: props.postInfo.postedBy },
                                });
                              }}
                            >
                              <img
                                title="Message"
                                src={chatImg}
                                alt="chat"
                                height={25}
                                width={25}
                              />
                            </div>
                            <BsGridFill
                              title="Tiiptok"
                              className="fs_20 rev_clr mr-3 c_pointer"
                              onClick={() => { handleNameClick(props.postInfo.postedBy, props.userType, props.postInfo.businessId) }}
                            />
                          </div>
                        )}
                        {/* </Col> */}
                      </Row>
                      {/* <StarRating
                        rating={props.postInfo.averageReviewCount}
                        disabled={true}
                        customClass="user-star"
                        lineH="lineH"
                      /> */}
                    </Col>
                  </Row>
                </div>
              </div>
            </span>
          </div>
        </Col>
        <Col sm={12} md={6} lg={4} className="p-0">
          <div>
            <p className="mb-0 fz_15 font-weight-400 gray_clr">
              {moment(props.postInfo.createdAt).format("D MMM YYYY [at] HH:mm")}
            </p>
          </div>
        </Col>
      </div>
      <div className="post-body">
        <p className="mb-2">{props.postInfo.comment}</p>
        <div className="image-gallery">
          <div className={`image-grid ${determineGrid()}`}>
            {props.postInfo.attachments
              .slice(0, visibleImages)
              .map((image, index) => (
                <div
                  key={index}
                  className={`image-item ${visibleImages < props.postInfo.attachments.length
                    ? "position-relative"
                    : ""
                    }`}
                >
                  {renderFile(image.filePath)}
                  {visibleImages < props.postInfo.attachments.length &&
                    index === 3 && (
                      <span
                        className="image-more c_pointer"
                        onClick={showMoreImages}
                      >
                        {"+"}
                        {props.postInfo.attachments.length - visibleImages}
                      </span>
                    )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="post-footer">
        <div className="row1">
          <div className="d-flex align-items-center">
            <img src={like_icn} width={22} height={22} />
            &nbsp; <span>{props.postInfo.likes}</span>
          </div>
          {/* <div className="d-flex align-items-center">
            <img src={like_icn} width={22} height={22} />
            &nbsp; <span>3</span>
          </div> */}
          <div
            className="d-flex align-items-center c_pointer"
            onClick={() => {
              setCommentsModal(true);
              setShowChildReply(false);
              setchildcurrentIndex(null);
            }}
          >
            <FaRegComment className="fs_20" />
            &nbsp; <span>{props.postInfo.postComments.length}</span>
          </div>
          {/* <div className="d-flex align-items-center c_pointer">
            <FaRegComment className="fs_20" />
            &nbsp; <span>{props.postInfo.postComments.length}</span>
          </div> */}
        </div>
        <div className="pl-2 pr-2 b_top_1">
          <div className="row2">
            {props.postInfo.userId === props.userId ? (
              <div className="d-flex align-items-center c_not_allowed">
                <LuThumbsUp className="fz_18 clr_gray" />
                &nbsp; <p className="mb-0 fnt_styl fz_15 clr_gray">Like</p>
              </div>
            ) : (
              <span
                className="d-flex align-items-center c_pointer"
                onClick={() =>
                  addPostLike(
                    props.postInfo.postId,
                    props.userId,
                    props.postInfo.liked
                  )
                }
              >
                {props.postInfo.liked === 1 ? (
                  <>
                    <HiThumbUp className="fz-22" style={{ color: "#0866FF" }} />
                    &nbsp;{" "}
                    <p
                      className="mb-0 fnt_styl fz_15"
                      style={{ color: "#0866FF" }}
                    >
                      Like
                    </p>
                  </>
                ) : (
                  <>
                    <LuThumbsUp className="fz_18" />
                    &nbsp; <p className="mb-0 fnt_styl fz_15">Like</p>
                  </>
                )}
              </span>
            )}
            {/* {props.postInfo.userId === props.userId ? (
              <div className="d-flex align-items-center c_not_allowed">
                <LuThumbsUp className="fz_18 clr_gray" />
                &nbsp; <p className="mb-0 fnt_styl fz_15 clr_gray">Like</p>
              </div>
            ) : (
              <span className="d-flex align-items-center c_pointer">
                <HiThumbUp className="fz-22" style={{ color: "#0866FF" }} />
                &nbsp;{" "}
                <p className="mb-0 fnt_styl fz_15" style={{ color: "#0866FF" }}>
                  Like
                </p>
              </span>
            )} */}
            {/* {(props.postInfo.userId === props.userId || (props.postInfo.preferenceList.length == 0  && props.postInfo.preferenceList[0]?.preferenceName == "view_only" || props.postInfo.preferenceList[1]?.preferenceName != "view_post_comment")) ? ( */}
            {(props.postInfo.userId === props.userId || (!props.postInfo.isFriend && props.postInfo.preferenceList.length == 0  && (props.postInfo.preferenceList[0]?.preferenceName == "view_only" || props.postInfo.preferenceList[1]?.preferenceName != "view_post_comment"))) ? (
              <div className="d-flex align-items-center c_not_allowed">
                <FaRegComment className="fz_18" /> &nbsp;{" "}
                <p className="mb-0 fnt_styl fz_15 clr_gray">Comment</p>
              </div>
            ) : (props.postInfo.preferenceList[1]?.value == true || props.postInfo.preferenceList[0]?.preferenceName == "view_and_comment") ? (
              <span
                className="d-flex align-items-center c_pointer"
                onClick={() => handleAddComment()}
              >
                <FaRegComment className="fz_18" /> &nbsp;{" "}
                <p className="mb-0 fnt_styl fz_15">Comment</p>
              </span>
            ) : (<div className="d-flex align-items-center c_not_allowed">
              <FaRegComment className="fz_18" /> &nbsp;{" "}
              <p className="mb-0 fnt_styl fz_15 clr_gray">Comment</p>
            </div>
          )}
            {/* {props.postInfo.userId === props.userId ? (
              <div className="d-flex align-items-center c_not_allowed">
                <FaRegComment className="fz_18" /> &nbsp;{" "}
                <p className="mb-0 fnt_styl fz_15 clr_gray">Comment</p>
              </div>
            ) : (
              <span className="d-flex align-items-center c_pointer">
                <FaRegComment className="fz_18" /> &nbsp;{" "}
                <p className="mb-0 fnt_styl fz_15">Comment</p>
              </span>
            )} */}
          </div>
        </div>
      </div>
      <CustomModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        post={true}
        title="Attachments"
        body={
          <>
            <div className="image-gallery">
              <div className={`image-grid ${determineGrid()}`}>
                {props.postInfo.attachments.map((img, idx) => (
                  <div key={idx} className="image-item">
                    {renderFile(img.filePath)}
                  </div>
                ))}
              </div>
            </div>
          </>
        }
      />
      <CustomModal
        show={addCmtModal}
        handleClose={() => setAddCmtModal(false)}
        post={true}
        title="Add Comment"
        body={
          <>
            <textarea
              placeholder="Type something..."
              className="form-control Comment_sty fz_14 h_100"
              maxLength={288}
              onChange={(e) => {
                setCommentTxt(e.target.value);
              }}
              value={commentTxt}
            />
            {cmtTxtError && <p className="fz-13">{cmtTxtError}</p>}
            <Button
              className="btn btn-default float-right mt-2"
              onClick={() => postComment(0, "parent")}
            >
              Post
            </Button>
          </>
        }
      />
      <CustomModal
        show={commentsModal}
        handleClose={() => setCommentsModal(false)}
        title="Comments"
        post={true}
        body={
          <>
            {props.postInfo.postComments &&
              props.postInfo.postComments.map((data) => (
                <Row>
                  <Col
                    className={`revCmtPop pb-0 ${showChildReply && postReplies.length != 0
                      ? "revcmt_bg"
                      : ""
                      }`}
                  >
                    <div className="d-flex">
                      <div>
                        <img
                          className="brd_rad h_w_25"
                          src={data.imagePath ? data.imagePath : profile}
                        />
                      </div>
                      <div
                        className="d-flex"
                        style={{ marginLeft: "8px", alignItems: "center" }}
                      >
                        {data.userName}
                        <span className="dat_icn">
                          <GoDotFill />
                        </span>{" "}
                        <div style={{ fontSize: "11px", color: "#959595" }}>
                          {" "}
                          {moment
                            .utc(data.createdAt)
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        </div>
                      </div>
                    </div>
                    <div className="ctTxt">
                      {/* {textWithLinks(data.commentText).map((word, index) => (
                        <span key={index}>{word} </span>
                      ))} */}
                      {data.commentText}
                    </div>
                    <div class="send d-flex gap_20 mb-2 mt-2">
                      <RatingBar
                        disabled={props.userId === data.userId ? true : false}
                        msgreply={true}
                        liked={data.liked}
                        likecount={data.likes}
                        dislikecount={data.dislikes}
                        handlelike={() =>
                          handlerplylikechange(data.id, data.postId, null)
                        }
                        handledislike={() =>
                          handlerplydislikechange(data.id, data.postId, null)
                        }
                        handleRmvlike={() =>
                          rplylikecntDecrement(data.id, data.postId, null)
                        }
                        labelVAlue={data.score}
                      />
                      {data.userId === props.userId ? (
                        <BiSolidCommentDetail className="cmt_icn clr_gray" />
                      ) : (
                        <BiSolidCommentDetail
                          className="cmt_icon c_pointer"
                          title="Add comment"
                          onClick={() => togglecomment(data.id)}
                        />
                      )}

                      <Row>
                        {data.postReplies.length != 0 && (
                          <span
                            className="rply c_pointer"
                            onClick={() =>
                              viewChildComments(data.postId, data.id)
                            }
                          >
                            {data.postReplies.filter(
                              (obj) =>
                                obj.id == data.id ||
                                obj.parentCommentId == data.id
                            ).length === 1
                              ? `${data.postReplies.filter(
                                (obj) =>
                                  obj.id == data.id ||
                                  obj.parentCommentId == data.id
                              ).length
                              } Reply `
                              : `${data.postReplies.filter(
                                (obj) =>
                                  obj.id == data.id ||
                                  obj.parentCommentId == data.id
                              ).length
                              } Replies `}
                            <span>
                              {childcurrentIndex == data.id ? (
                                <IoMdArrowDropup />
                              ) : (
                                <IoMdArrowDropdown />
                              )}
                            </span>
                          </span>
                        )}
                      </Row>
                    </div>
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="position-relative mb-2">
                          <textarea
                            placeholder="Type something..."
                            className="form-control Comment_sty fz_14 h_100"
                            maxLength={288}
                            onChange={(e) => {
                              setCommentTxt(e.target.value);
                            }}
                            value={commentTxt}
                          />
                          {cmtTxtError && (
                            <p className="fz-13">{cmtTxtError}</p>
                          )}
                          <Button
                            className="btn btn-primary cmt_btn mr-2"
                            onClick={() => postComment(data.id, "child")}
                          >
                            Post
                          </Button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {childcurrentIndex == data.id && postReplies.length != 0
                      ? postReplies.map((item, index) =>
                        item.id == data.id ||
                          item.parentCommentId == data.id ? (
                          <div className="d-flex">
                            <Col className="revchdcmt pb-0">
                              <div className="d-flex">
                                <div>
                                  <img
                                    className="brd_rad h_w_25"
                                    src={
                                      item.imagePath
                                        ? item.imagePath
                                        : profile
                                    }
                                  />
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    marginLeft: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* {item.userName} */}
                                  <span className="dat_icn">
                                    <GoDotFill />
                                  </span>{" "}
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {" "}
                                    {moment
                                      .utc(item.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </div>
                              <div className="ctTxt">
                                <span className="rep_men">
                                  {"@" + item.userName + " "}
                                </span>
                                {item.commentText}
                              </div>
                              <div class="send d-flex gap_20 mb-2 mt-2">
                                <RatingBar
                                  disabled={
                                    item.userId === props.userId
                                      ? true
                                      : false
                                  }
                                  msgreply={true}
                                  liked={item.liked}
                                  likecount={item.likes}
                                  dislikecount={item.dislikes}
                                  handlelike={() =>
                                    handlerplylikechange(
                                      item.id,
                                      data.postId,
                                      item.parentCommentId
                                    )
                                  }
                                  handledislike={() =>
                                    handlerplydislikechange(
                                      item.id,
                                      data.postId,
                                      item.parentCommentId
                                    )
                                  }
                                  handleRmvlike={() =>
                                    rplylikecntDecrement(
                                      item.id,
                                      data.postId,
                                      item.parentCommentId
                                    )
                                  }
                                  labelVAlue={item.score}
                                />
                                {item.userId === props.userId ? (
                                  <BiSolidCommentDetail className="cmt_icn clr_gray" />
                                ) : (
                                  <BiSolidCommentDetail
                                    className="cmt_icon c_pointer"
                                    title="Add comment"
                                    onClick={() =>
                                      togglechildcomment(item.id)
                                    }
                                  />
                                )}
                              </div>
                              {childreplycurrentIndex == item.id ? (
                                <Row>
                                  <div className="position-relative p-0">
                                    <textarea
                                      placeholder="Type something..."
                                      className="form-control Comment_sty fz_14 h_100"
                                      maxLength={288}
                                      onChange={(e) => {
                                        setCommentTxt(e.target.value);
                                      }}
                                      value={commentTxt}
                                    />
                                    {cmtTxtError && (
                                      <p className="fz-13">{cmtTxtError}</p>
                                    )}
                                    <Button
                                      className="btn btn-primary cmt_btn mr-2"
                                      onClick={() =>
                                        postComment(data.id, "child")
                                      }
                                    >
                                      Post
                                    </Button>
                                  </div>
                                </Row>
                              ) : (
                                ""
                              )}
                            </Col>
                          </div>
                        ) : (
                          ""
                        )
                      )
                      : ""}
                  </Col>
                </Row>
              ))}
          </>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
    userTypeInfo: state.user.userTypeInfo,
    busnAdminId: state.user.busnAdminId,
  };
};

export default connect(mapStateToProps)(PostCard);
