import logo from "../../assets/images/tiipstr_lgo.svg";
import React, { useState, useEffect, useRef } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TwoFactorAuth = () => {
  const [timerActive, setTimerActive] = useState(true);
  const storedTime = localStorage.getItem("remainingTime");
  const initialTime = storedTime
    ? JSON.parse(storedTime)
    : { minutes: 5, seconds: 0 };
  const [verificationCode, setVerificationCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [minutes, setMinutes] = useState(initialTime.minutes);
  const [seconds, setSeconds] = useState(initialTime.seconds);
  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const intervalRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;
  const method = location.state?.method;
  const email = location.state?.email;
  const phone = location.state?.phone;

  useEffect(() => {
    if (timerActive) {
      localStorage.setItem(
        "remainingTime",
        JSON.stringify({ minutes, seconds })
      );
      intervalRef.current = setInterval(() => {
        countDown();
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [minutes, seconds]);

  function countDown() {
    if (seconds === 0) {
      if (minutes !== 0) {
        setSeconds(59);
        setMinutes((min) => min - 1);
      } else {
        let mins = 0;
        let sec = 0;
        setSeconds(sec);
        setMinutes(mins);
        if (mins === 0 && sec === 0) {
          setTimerActive(false);
        }
      }
    } else {
      setSeconds((sec) => sec - 1);
    }
  }

  const Start = () => {
    setMinutes(5);
    setSeconds(0);
  };

  const ResendCode = async () => {
    try {
      const response = await window.apiService.sendCode({
        id: userId,
        type: "2fa",
      });
      if (response.data.status == "OK") {
        toast.success(response.data.message);
        setTimerActive(true);
        Start();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  const handleVerify = async () => {
    if (verificationCode.trim() === "") {
      setError("Verification code is required.");
      return;
    }
    if (userId) {
      try {
        const response = await window.apiService.verifyCode({
          id: userId,
          type: "2fa",
          verificationCode: verificationCode,
        });
        if (response.data.status == "OK") {
          toast.success(response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              navigate("/dashboard");
            },
          });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (e) => {
    setVerificationCode(e.target.value);
    setError("");
  };

  return (
    <>
      <div className="two-factor-auth-container">
        <div>
          <img className="ImgLogo" src={logo} />
        </div>
        <h2 className="two-factor-auth-title">Help us protect your account</h2>
        <p className="two-factor-auth-description">
          For added security, please verify your identity by entering the code
          sent to your{" "}
          {method && (
            <span>
              {method === "email"
                ? `Email: ${email.slice(0, 4) +
                "x".repeat(Math.max(0, email.length - 11))
                }`
                : method === "phone"
                  ? `Phone: ${"x".repeat(Math.max(0, phone.length - 4)) + phone.slice(-4)
                  }`
                  : method === "both"
                    ? `Email: ${email.slice(0, 4) +
                    "x".repeat(Math.max(0, email.length - 11))
                    } and Phone: ${"x".repeat(Math.max(0, phone.length - 4)) + phone.slice(-4)
                    }`
                    : ""}
            </span>
          )}
        </p>
        <div className="two-factor-auth-input-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter Verification Code"
            value={verificationCode}
            onChange={handleInputChange}
            className="two-factor-auth-input"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleVerify();
              }
            }}
          />
          <span className="password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        {error && <p className="text-danger">{error}</p>}
        <div className="row text-end" style={{ marginTop: "5px" }}>
          {timerMinutes === "00" && timerSeconds === "00" ? (
            ""
          ) : (
            <div className="float-right fz_13" style={{ marginTop: "5px" }}>
              {timerMinutes === "00" ? (
                <span className="">
                  {timerMinutes}:{timerSeconds}
                </span>
              ) : (
                <span>
                  {timerMinutes}:{timerSeconds}
                </span>
              )}
            </div>
          )}
          {timerMinutes === "00" && timerSeconds === "00" ? (
            <div
              className="float-right text-warning fz-12"
              style={{ marginTop: "5px" }}
            >
              Code Expired
            </div>
          ) : (
            ""
          )}
        </div>
        <button onClick={handleVerify} className="two-factor-auth-button">
          Verify Code
        </button>
        <p className="rsnd a_clr" onClick={ResendCode}>
          Resend code
        </p>
      </div>
    </>
  );
};

export default TwoFactorAuth;
