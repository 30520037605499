import { Card, Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { BsPlusLg } from "react-icons/bs";
import { FaPencil, FaTrashCan } from "react-icons/fa6";
import SideBar from "./AdminSideBar";
import HeaderMenu from "./HeaderMenu";
import BreadCrumb from "../plugins/Breadcrumb";
import { Input } from "reactstrap";

const UserTypes = () => {
  const [roleList, setRoleList] = useState("");
  useEffect(() => {
    loadRoleList();
  }, []);

  const loadRoleList = async () => {
    try {
      const response = await window.apiService.loadUserRoles();
      setRoleList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          <BreadCrumb />
          <Card className="shade h-500">
            <div className="role_container">
              <Row className="m_b18">
                <Col sm={12} md={12} lg={12} xl={12}>
                  Manage User Types
                </Col>
              </Row>
              <Row className="m_b8">
                <Col sm={12} md={6} lg={6} xl={6} className="">
                  <Input placeholder="Enter a new Role" type="text"></Input>
                </Col>
                <Col
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  className="align-items-center"
                >
                  <BsPlusLg className="c_pointer plus_icn" />
                </Col>
              </Row>
              {roleList &&
                roleList.map((role) => (
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input
                        placeholder="Enter a new Role"
                        className="m_b8"
                        type="text"
                        value={role.name}
                      ></Input>
                    </Col>
                    <Col
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      className="align-items-center"
                    >
                      <div className="d-flex">
                        <div className="m_r15">
                          <FaPencil
                            title="Edit"
                            className="c_pointer icon_usrRole"
                          />
                        </div>
                        <div className="m_r15">
                          <FaTrashCan
                            title="Delete"
                            style={{ color: "#f16969" }}
                            className="c_pointer icon_usrRole"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))}
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default UserTypes;
