import { Link, useLocation } from "react-router-dom";
import CustomBreadcrumb from "./CustomBreadCrumb";

const BreadCrumb = () => {
  const location = useLocation();
  const breadcrumbPaths = location.pathname
    .split("/")
    .filter((path) => path !== "")
    .map((path, index, paths) => {
      const link = "/" + paths.slice(0, index + 1).join("/");
      return { name: path, link: link };
    });

  return (
    <>
      <CustomBreadcrumb paths={breadcrumbPaths} />
    </>
  );
};

export default BreadCrumb;
