import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from 'lodash';
import { Nav, Modal, Navbar } from "react-bootstrap";
import profile from "../../assets/images/profile.jpg";
import { NavLink } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FaSearch, FaUser } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { PiBellBold } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { TbLogout } from "react-icons/tb";
import { HiLocationMarker } from "react-icons/hi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { commonUtils } from "../CommonUtils";
import "./headerMenu.css";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import { connect } from "react-redux";
import { logoutAction } from "../../Redux/Actions/userAction";
import { useLocation } from "react-router-dom";
import { useToast } from "../Toast";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { followId } from "../../Redux/Actions/userAction";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import LoaderOverlay from "../../components/plugins/LoaderOverlay";
import addUser from "../../assets/images/add_user.jpg";
import { PiPhoneFill } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import InviteModal from "../../components/plugins/InviteModal";

function HeaderMenu(props) {
  const location = useLocation();
  const toast = useToast();
  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filter, setfilter] = useState(false);
  const [bannerName, setBannerName] = useState("");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [profTypeList, setProfTypeList] = useState("");
  const [userProfList, setUserProfList] = useState("");
  const [userBusinessList, setUserBusinessList] = useState("");
  const [userData, setUserData] = useState("");
  const [dropdownOpenIdx, setDropdownOpenIdx] = useState("");
  const [busnAdminId, setBusnAdminId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const latestSearchTermRef = useRef('');

  const handleInviteModal = () => {
    setShowInviteModal(!showInviteModal);
  };

  const Logout = async () => {
    dispatch(logoutAction());
    // sessionStorage.removeItem("rememberMeToken");
    localStorage.removeItem("userdetail");
    navigate("/login");
    if (userId) {
      try {
        const response = await window.apiService.logout(userId, {
          isActive: 0,
        });
        console.log("response", response);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleToggle = () => {
    setCollapseOpen(!collapseOpen);
  };
  useEffect(() => {
    handleDynamicName();
    loadProfessionalList();
  }, []);
  useEffect(() => {
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserProfList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Professional")
      );
    }
    if (props.userTypeInfo != undefined && props.userTypeInfo != "") {
      setUserBusinessList(
        props.userTypeInfo.filter((st) => st.userTypeName == "Business")
      );
    }
  }, [props]);

  const handleDynamicName = () => {
    setBannerName(commonUtils.getBannerName(location.pathname));
  };
  const loadProfessionalList = async () => {
    try {
      const response = await window.apiService.professionalList({
        userTypeId: 2,
      });
      setProfTypeList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = useCallback(
    _.debounce(async (searchterm) => {
      if (searchterm) {
        try {
          let formData = new FormData();
          formData.append("keyword", searchterm);
          formData.append(
            "profileId",
            props.userType === "Personal" ? "" : props.profileId
          );
          formData.append("userType", props.userType);
          formData.append("userId", props.userId);

          const response = await window.apiService.overallSearch(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (searchterm === latestSearchTermRef.current) {
            const filteredInfo = response.data.data.filter(
              (data) =>
                data.userType !== 'Admin' &&
                data.userType !== 'Support' &&
                data.id !== props.userId
            );
            console.log('filteredInfo', filteredInfo);
            setFilterData(filteredInfo);
            if (dropdownOpenIdx !== '') setUserData(response.data.data[dropdownOpenIdx]);
            setfilter(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 300),
    [props.userType, props.profileId, props.userId, dropdownOpenIdx]
  );

  const unformatphone = (value) => {
    if (value != "" && value.trim().startsWith("+")) {
      let phoneData = value.trim().split(" ");
      if (phoneData[1] != "" && phoneData[1] != undefined) {
        let phone_no = phoneData[1].replace(/[()-]/g, "");
        return phone_no;
      } else {
        return phoneData;
      }
    } else {
      if (value != "" && value != undefined) {
        let phone_no = value.replace(/[()-]/g, "");
        return phone_no;
      } else return value;
    }
  };
  const handleChange = (event) => {
    let unformatData = unformatphone(event.target.value);
    setSearchTerm(event.target.value);
    // setName(event.target.value);
    latestSearchTermRef.current = unformatData;
    if (event.target.value.length > 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    handleSearch(unformatData);
  };

  const handleKeyPress = (event) => {
    let unformatData = unformatphone(event.target.value);
    if (event.key === "Enter") {
      latestSearchTermRef.current = unformatData;
      handleSearch(unformatData);
    }
  };

  const handleFollowRequest = (
    isFollow,
    userId,
    id,
    followingtype,
    followedtype,
    followedTypeId,
    followingTypeId
  ) => {
    if (userType == "Personal") {
      followingTypeId = null;
    }
    let userInfo = {};
    if (!isFollow) {
      userInfo = {
        followingUserId:
          followingtype === "Business" ? props.busnAdminId : userId,
        followedUserId: id,
        followedUserType: followedtype,
        followingUserType: followingtype,
        followedTypeId: followedTypeId,
        followingTypeId: followingTypeId,
        isActive: 1,
      };
      followuser(userInfo);
      setIsOpen(false);
      setSearchTerm("");
    } else {
      userInfo = {
        followingUserId: userId,
        followedUserId: id,
        followedUserType: followedtype,
        followingUserType: followingtype,
        followedTypeId: followedTypeId,
        followingTypeId: followingTypeId,
      };
      unfollowuser(userInfo);
      setIsOpen(false);
      setSearchTerm("");
    }
  };
  const followuser = async (userInfo) => {
    try {
      const response = await window.apiService.addFollower(userInfo);
      if (
        response.data.status == "OK" &&
        response.data.message == "Followers already exists."
      ) {
        toast.success(response.data.message);
        handleSearch(searchTerm);
        if (bannerName == "Add User")
          window.dispatchEvent(new Event("reloadUsers"));
        else if (bannerName == "Following")
          window.dispatchEvent(new Event("reloadFollowingUsers"));
      } else if (
        response.data.status == "OK" &&
        response.data.message == "Followers Added successfully."
      ) {
        toast.success("Followed successfully");
        handleSearch(searchTerm);
        if (bannerName == "Add User")
          window.dispatchEvent(new Event("reloadUsers"));
        else if (bannerName == "Following")
          window.dispatchEvent(new Event("reloadFollowingUsers"));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const unfollowuser = async (userInfo) => {
    try {
      const response = await window.apiService.unFollowUser(userInfo);
      if (response.data.status == "OK") {
        toast.success("Unfollowed successfully");
        handleSearch(searchTerm);
        if (bannerName == "Add User")
          window.dispatchEvent(new Event("reloadUsers"));
        else if (bannerName == "Following")
          window.dispatchEvent(new Event("reloadFollowingUsers"));
      } else {
        toast.error(response);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };

  const handledropdownOpen = (idx, user) => {
    setDropdownOpen(true);
    setUserData(user);
    setDropdownOpenIdx(idx);
  };
  const viewUser = (id, type, profileId) => {
    dispatch(followId(id, type, profileId));
    navigate("/dashboard/finduser/view", {
      state: { userId: id, userType: type, profileId: profileId },
    });
  };
  return (
    <>
      <LoaderOverlay isLoading={isLoading} />
      <div className="headerMenu">
        <Navbar expand="lg" className="menu-navbar header-menu">
          <Container className="container_width bg-white">
            <Navbar.Brand>
              <div className="flex-column">
                <div>
                  <h1 className="tiipstr">{bannerName}</h1>
                </div>
                <div className="d-flex align-items-center">
                  <p className="opacity-8 mb-0 font-weight-500 fz_15">
                    My Current Profile&nbsp;
                  </p>
                  {props.userType &&
                    bannerName &&
                    (props.userType == "Personal" ? (
                      <div className="hd_badge">Personal</div>
                    ) : props.userType == "Professional" ? (
                      <div className="hd_badgePro">
                        Professional&nbsp;
                        {userProfList.length > 0 &&
                          "(" +
                          userProfList.filter(
                            (user) => user.professionalId == props.profileId
                          )[0]?.professionalType +
                          ")"}
                      </div>
                    ) : (
                      <div className="hd_badgeBus">
                        Business&nbsp;
                        {userBusinessList.length > 0 &&
                          "(" +
                          userBusinessList.filter(
                            (user) => user.businessId == props.profileId
                          )[0]?.companyName +
                          ")"}
                      </div>
                    ))}
                </div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle} />
            <Navbar.Collapse id="navbar-nav" in={collapseOpen} className="flex-end">
              <div className="d-flex justify-content-between">
                <UncontrolledDropdown
                  nav
                  isOpen={isOpen}
                  toggle={() => setIsOpen(!isOpen)}
                >
                  <DropdownToggle nav>
                    <div class="input-group hr_search m-2">
                      <input
                        class="form-control py-2 border-right-0 border"
                        placeholder="Search for name, number, or email..."
                        value={searchTerm}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        id="search-input"
                      />
                      {searchTerm && (
                        <span class="input-group-append c_pointer">
                          <div class="input-group-text gble_bg_clr rounded-0">
                            <IoClose
                              className="search_icon"
                              onClick={() => setSearchTerm("")}
                            />
                          </div>
                        </span>
                      )}
                      <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                          <FiSearch
                            className="search_icon"
                            onClick={() => handleSearch(searchTerm)}
                          />
                        </div>
                      </span>
                    </div>
                  </DropdownToggle>
                  {searchTerm && filterData.length > 0 ? (
                    <DropdownMenu className="dropdown-menu-arrow">
                      <DropdownItem className="noti-title" header tag="div">
                        <div className="searchBox">
                          {filterData.length > 0 ? (
                            filterData.map((data, i) =>
                              data.businessInfo != null &&
                                data.businessInfo != undefined ? (
                                <Card className="box_shadow" key={i}>
                                  <Col lg={12}>
                                    <div className="d-flex align-items-center">
                                      <Col lg={2} className="pl-2 pr-2">
                                        {" "}
                                        <img
                                          className="brd_rad"
                                          width="30"
                                          height="30"
                                          src={
                                            data.businessInfo?.logo
                                              ? data.businessInfo?.logo
                                              : profile
                                          }
                                        />
                                      </Col>
                                      <Col lg={10} className="pl-2 pr-2">
                                        <div className="d-flex">
                                          <Col lg={9} className="p-2">
                                            <div className="text-left d-flex">
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  navigate(
                                                    "/dashboard/viewuser",
                                                    {
                                                      state: {
                                                        userId: data.id,
                                                        profileId:
                                                          data.businessInfo?.id,
                                                        userType: "Business",
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                {data.businessInfo?.companyName}
                                                &nbsp;
                                                {/* <span className="Busn_clr">
                                                {"(" +
                                                  data.businessInfo
                                                    ?.companyName +
                                                  ")"}
                                              </span> */}
                                              </a>
                                            </div>
                                            <p className="text-lowercase">
                                              {data.businessInfo?.email}
                                            </p>
                                            {data.businessInfo?.phone ? (
                                              <div className="text-left d-flex">
                                                {data.businessInfo?.phone ? (
                                                  <PiPhoneFill className="mt_4" />
                                                ) : (
                                                  ""
                                                )}
                                                &nbsp;{" "}
                                                <p className="text-lowercase">
                                                  {data.businessInfo?.phone
                                                    ? data.businessInfo?.phone
                                                    : "-"}
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {data.businessInfo?.location ? (
                                              <div className="text-left d-flex">
                                                <HiLocationMarker className="mt_4" />
                                                &nbsp;
                                                <p className="text-lowercase">
                                                  {data.businessInfo?.location}
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Col>
                                          {data.businessInfo.userId !==
                                            userId && (
                                              <Col lg={3} className="p-2">
                                                {data.businessInfo
                                                  ?.isFollowBusiness ? (
                                                  <Button
                                                    className="btn btn-sm remove_btn"
                                                    title="Unfollow"
                                                    onClick={() =>
                                                      handleFollowRequest(
                                                        data.businessInfo
                                                          .isFollowBusiness,
                                                        userId,
                                                        data.businessInfo
                                                          .businessUser.userId,
                                                        userType,
                                                        "Business",
                                                        data.businessInfo.id,
                                                        props.profileId
                                                      )
                                                    }
                                                  >
                                                    Unfollow
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    className="btn btn-sm review_btn"
                                                    title="Follow"
                                                    onClick={() =>
                                                      handleFollowRequest(
                                                        data.businessInfo
                                                          .isFollowBusiness,
                                                        userId,
                                                        data.businessInfo
                                                          .businessUser.userId,
                                                        userType,
                                                        "Business",
                                                        data.businessInfo.id,
                                                        props.profileId
                                                      )
                                                    }
                                                  >
                                                    Follow
                                                  </Button>
                                                )}
                                                {/* {data.isFollow?<Button className="btn btn-sm remove_btn" title="Unfollow" onClick={() => unfollowuser(data.id)}>Unfollow</Button>:
                                        <Button className="btn btn-sm review_btn" title="Follow" onClick={() => followuser(data.id)}>Follow</Button>} */}
                                              </Col>
                                            )}
                                        </div>
                                      </Col>
                                    </div>
                                  </Col>
                                </Card>
                              ) : (
                                <Card className="box_shadow" key={i}>
                                  <Col lg={12}>
                                    <div className="d-flex align-items-center">
                                      <Col lg={2} className="pl-2 pr-2">
                                        {" "}
                                        <img
                                          className="brd_rad"
                                          width="30"
                                          height="30"
                                          src={
                                            data.imagePath
                                              ? data.imagePath
                                              : profile
                                          }
                                        />
                                      </Col>
                                      <Col lg={10} className="pl-2 pr-2">
                                        <div className="d-flex">
                                          <Col lg={9} className="p-2">
                                            <div className="text-left d-flex">
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  navigate(
                                                    "/dashboard/viewuser",
                                                    {
                                                      state: {
                                                        userId: data.id,
                                                        profileId: null,
                                                        userType: "Personal",
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                {data.name}&nbsp;
                                              </a>
                                              <p className="text-lowercase of-ellipsis">
                                                {data.isPublic ? (
                                                  <span
                                                    title={data.email}
                                                  >{`(${data.email})`}</span>
                                                ) : (
                                                  <span
                                                    title={`(${data.email.slice(
                                                      0,
                                                      4
                                                    )}${"x".repeat(
                                                      Math.max(
                                                        0,
                                                        data.email.length - 4
                                                      )
                                                    )})`}
                                                  >{`(${data.email.slice(
                                                    0,
                                                    4
                                                  )}${"x".repeat(
                                                    Math.max(
                                                      0,
                                                      data.email.length - 4
                                                    )
                                                  )})`}</span>
                                                )}
                                              </p>
                                            </div>
                                            {data.phone ? (
                                              <div className="text-left d-flex">
                                                {data.phone ? (
                                                  <PiPhoneFill className="mt_4" />
                                                ) : (
                                                  ""
                                                )}
                                                &nbsp;{" "}
                                                <p className="text-lowercase">
                                                  {data.phone
                                                    ? data.isPublic
                                                      ? data.phone
                                                      : "x".repeat(
                                                        Math.max(
                                                          0,
                                                          data.phone.length - 4
                                                        )
                                                      ) + data.phone.slice(-4)
                                                    : "-"}
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {data.location ? (
                                              <div className="text-left d-flex">
                                                <HiLocationMarker className="mt_4" />
                                                &nbsp;
                                                <p className="text-lowercase">
                                                  {data.location}
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Col>
                                          <Col lg={3} className="p-2">
                                            {data.professionalList &&
                                              data.professionalList.length > 0 ? (
                                              <Button
                                                className="btn btn-sm view_btn"
                                                onClick={() =>
                                                  handledropdownOpen(i, data)
                                                }
                                              >
                                                View
                                              </Button>
                                            ) : data.isFollow ? (
                                              <Button
                                                className="btn btn-sm remove_btn"
                                                title="Unfollow"
                                                onClick={() =>
                                                  handleFollowRequest(
                                                    data.isFollow,
                                                    userId,
                                                    data.id,
                                                    userType,
                                                    data.userType,
                                                    null,
                                                    props.profileId
                                                  )
                                                }
                                              >
                                                Unfollow
                                              </Button>
                                            ) : (
                                              <Button
                                                className="btn btn-sm review_btn"
                                                title="Follow"
                                                onClick={() =>
                                                  handleFollowRequest(
                                                    data.isFollow,
                                                    userId,
                                                    data.id,
                                                    userType,
                                                    data.userType,
                                                    null,
                                                    props.profileId
                                                  )
                                                }
                                              >
                                                Follow
                                              </Button>
                                            )}
                                            {/* {data.isFollow?<Button className="btn btn-sm remove_btn" title="Unfollow" onClick={() => unfollowuser(data.id)}>Unfollow</Button>:
                                        <Button className="btn btn-sm review_btn" title="Follow" onClick={() => followuser(data.id)}>Follow</Button>} */}
                                          </Col>
                                        </div>
                                      </Col>
                                    </div>
                                  </Col>
                                </Card>
                              )
                            )
                          ) : filter ? (
                            <div className="row col-md-12 d-align mx-0">
                              <div className="no-s-data w-100 text-center fz-10">
                                Sorry, there is no data to be displayed
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  ) : filter && searchTerm ? (
                    <DropdownMenu className="dropdown-menu-arrow">
                      <DropdownItem className="noti-title" header tag="div">
                        <div>{searchTerm}</div>
                        <div className="text-center">
                          <Button
                            className="btn btn-sm review_btnnw"
                            title="Follow"
                            onClick={handleInviteModal}
                          >
                            Invite User
                          </Button>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  ) : (
                    ""
                  )}
                </UncontrolledDropdown>
                <div className="collapse-close">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={handleToggle}
                  >
                    <span />
                    <span />
                  </button>
                </div>
              </div>
              <div>
                <img
                  src={addUser}
                  className="dashboardTabIcon c_pointer"
                  onClick={() => navigate("/dashboard/finduser")}
                  title="Add User"
                />
              </div>

              <Nav className="src/App.css">
                <Nav.Link
                  as={NavLink}
                  to="/dashboard/notification"
                  style={{ paddingLeft: "0" }}
                >
                  <div>
                    <PiBellBold className="bell_icon" />
                    {props.newMentionCount != 0 ? (
                      <div className="bell_red"></div>
                    ) : (
                      ""
                    )}
                  </div>
                </Nav.Link>
                <Nav.Link className="p-0">
                  <UncontrolledDropdown nav className="top_5">
                    <DropdownToggle nav className="p-0">
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            src={props.imagePath ? props.imagePath : profile}
                          />
                        </span>
                        <span className="logo_name">
                          {props.userName}
                          <span>
                            <IoIosArrowDown />
                          </span>
                        </span>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow drop_header m-0">
                          Welcome!
                        </h6>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => navigate("/dashboard/profile")}
                      >
                        <FaUser className="ni ni-single-02" />
                        <span>My profile</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => navigate("/dashboard/user_settings")}
                      >
                        <IoSettingsSharp className="ni ni-single-02" />
                        <span>Settings</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        href="#pablo"
                        onClick={() => setShowConfirmPopup(true)}
                      >
                        <TbLogout className="ni ni-user-run" />
                        <span>Logout</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="header pt-0"></div>
      {/* ----------- Logout Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Logout Confirmation
          </Modal.Title>
          <span onClick={() => setShowConfirmPopup(false)} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to Logout?</span>
        </Modal.Body>
        <Modal.Footer className="bordr pad_13">
          <Button variant="primary" className="del_bttn" onClick={Logout}>
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setShowConfirmPopup(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* -----------  popup ---------- */}
      <Modal
        size="lg"
        show={isDropdownOpen}
        onHide={() => setDropdownOpen(false)}
      >
        <Modal.Header>
          <Modal.Title style={{ fontSize: "20px", fontWeight: 600 }}>
            User Profiles
          </Modal.Title>
          <span
            onClick={() => {
              setDropdownOpen(false);
            }}
            title="Close"
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="p-3">
          {userData && (
            <Col sm={12} md={12} lg={12} xl={12}>
              <Row className="d-flex align-items-center justify-content-center">
                <Col sm={12} md={5} lg={4} xl={3} className="usr_card">
                  <Card className="usr_crd rounded-0">
                    <div className="d-flex justify-content-end">
                      <div className="usr_brnd usr_brnd_psnl">
                        <span className="m-1">Personal</span>
                      </div>
                    </div>
                    <div className="user text-center d-flex justify-content-center">
                      <div className="follow_profile">
                        <img
                          src={
                            userData.imagePath ? userData.imagePath : profile
                          }
                          className="rounded-circle"
                          width="60"
                          height="60"
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-center">
                      <h6 className="mb-0">{userData.name}</h6>
                      <div className="d-flex justify-content-between">
                        <div className="stats">
                          <span>{userData.followersCount}</span>
                          <h6>Followers</h6>
                        </div>
                        <div className="stats">
                          <span>{userData.followingCount}</span>
                          <h6>Following</h6>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="stats">
                        <Button
                          className="btn btn-sm u_view_btn"
                          onClick={() =>
                            viewUser(userData.id, userData.userType)
                          }
                        >
                          View
                        </Button>
                      </div>
                      <div className="stats">
                        {userData.isFollow ? (
                          <Button
                            className="btn btn-sm u_remove_btn"
                            title="Unfollow"
                            onClick={() =>
                              handleFollowRequest(
                                userData.isFollow,
                                userId,
                                userData.id,
                                userType,
                                userData.userType,
                                null,
                                props.profileId
                              )
                            }
                          >
                            Unfollow
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-sm u_review_btn"
                            title="Follow"
                            onClick={() =>
                              handleFollowRequest(
                                userData.isFollow,
                                userId,
                                userData.id,
                                userType,
                                userData.userType,
                                null,
                                props.profileId
                              )
                            }
                          >
                            Follow
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card>
                </Col>
                {userData.professionalList &&
                  userData.professionalList.length > 0 &&
                  userData.professionalList.map((profession, index) => (
                    <Col
                      sm={12}
                      md={5}
                      lg={4}
                      xl={3}
                      className="usr_card"
                      key={index}
                    >
                      <Card className="usr_crd rounded-0">
                        <div className="d-flex justify-content-end">
                          <div className="usr_brnd usr_brnd_pro">
                            <span className="m-1">Professional</span>
                          </div>
                        </div>
                        <div className="user text-center d-flex justify-content-center">
                          <div className="follow_profile">
                            <img
                              src={profile}
                              className="rounded-circle"
                              width="60"
                              height="60"
                            />
                          </div>
                        </div>
                        <div className="mt-2 text-center">
                          <h6 className="mb-0">{profession.professionType}</h6>
                          <div className="d-flex justify-content-between">
                            <div className="stats">
                              <span>
                                {profession.professionalFollowerCount}
                              </span>
                              <h6>Followers</h6>
                            </div>
                            <div className="stats">
                              <span>
                                {profession.professionalFollowingCount}
                              </span>
                              <h6>Following</h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-around">
                          <div className="stats">
                            <Button
                              className="btn btn-sm u_view_btn"
                              onClick={() =>
                                viewUser(
                                  profession.userId,
                                  "Professional",
                                  profession.id
                                )
                              }
                            >
                              View
                            </Button>
                          </div>
                          <div className="stats">
                            {profession.isFollowProfessional ? (
                              <Button
                                className="btn btn-sm u_remove_btn"
                                title="Unfollow"
                                onClick={() =>
                                  handleFollowRequest(
                                    profession.isFollowProfessional,
                                    userId,
                                    profession.userId,
                                    userType,
                                    "Professional",
                                    profession.id,
                                    props.profileId
                                  )
                                }
                              >
                                Unfollow
                              </Button>
                            ) : (
                              <Button
                                className="btn btn-sm u_review_btn"
                                title="Follow"
                                onClick={() =>
                                  handleFollowRequest(
                                    profession.isFollowProfessional,
                                    userId,
                                    profession.userId,
                                    userType,
                                    "Professional",
                                    profession.id,
                                    props.profileId
                                  )
                                }
                              >
                                Follow
                              </Button>
                            )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                {userData.businessData &&
                  userData.businessData.length > 0 &&
                  userData.businessData.map((business, index) => (
                    <Col
                      sm={12}
                      md={5}
                      lg={4}
                      xl={3}
                      className="usr_card"
                      key={index}
                    >
                      <Card className="usr_crd rounded-0">
                        <div className="d-flex justify-content-end">
                          <div className="usr_brnd usr_brnd_bus">
                            <span className="m-1">Business</span>
                          </div>
                        </div>
                        <div className="user text-center d-flex justify-content-center">
                          <div className="follow_profile">
                            <img
                              src={profile}
                              className="rounded-circle"
                              width="60"
                              height="60"
                            />
                          </div>
                        </div>
                        <div className="mt-2 text-center">
                          <h6 className="mb-0">{business.companyName}</h6>
                          <div className="d-flex justify-content-between">
                            <div className="stats">
                              <span>{business.businessFollowerCount}</span>
                              <h6>Followers</h6>
                            </div>
                            <div className="stats">
                              <span>{business.businessFollowingCount}</span>
                              <h6>Following</h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-around">
                          <div className="stats">
                            <Button
                              className="btn btn-sm u_view_btn"
                              onClick={() =>
                                viewUser(
                                  business.userId,
                                  "Business",
                                  business.id
                                )
                              }
                            >
                              View
                            </Button>
                          </div>
                          {business.userId !== userId && (
                            <div className="stats">
                              {business.isFollowBusiness ? (
                                <Button
                                  className="btn btn-sm u_remove_btn"
                                  title="Unfollow"
                                  onClick={() =>
                                    handleFollowRequest(
                                      business.isFollowBusiness,
                                      userId,
                                      business.businessUser.userId,
                                      userType,
                                      "Business",
                                      business.id,
                                      props.profileId
                                    )
                                  }
                                >
                                  Unfollow
                                </Button>
                              ) : (
                                <Button
                                  className="btn btn-sm u_review_btn"
                                  title="Follow"
                                  onClick={() =>
                                    handleFollowRequest(
                                      business.isFollowBusiness,
                                      userId,
                                      business.businessUser.userId,
                                      userType,
                                      "Business",
                                      business.id,
                                      props.profileId
                                    )
                                  }
                                >
                                  Follow
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          )}
        </Modal.Body>
      </Modal>
      {/* ----------- Invite user popup ---------- */}
      {showInviteModal && (
        <InviteModal showInvite={showInviteModal} handleClose={handleInviteModal} />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userName: state.user.userName,
    imagePath: state.user.imagePath,
    userId: state.user.userId,
    email: state.user.email,
    newMentionCount: state.user.newMentionCount,
    userTypeInfo: state.user.userTypeInfo,
    userType: state.user.userType,
    profileId: state.user.profileId,
    authToken: state.user.token,
    busnAdminId: state.user.busnAdminId,
  };
};
export default connect(mapStateToProps)(HeaderMenu);
