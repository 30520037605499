import StarRating from "./StarRating";
import profile from "../../../assets/images/profile.jpg";
import { Card, Col, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { BsShareFill } from "react-icons/bs";
import moment from "moment";
import logoImg from "../../../assets/images/tiipstrlogo.jpg";
import chatImg from "../../../assets/images/dash_chat.png";
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useRef } from "react";
import { useToast } from "../../Toast";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import SideBar from "../SideBar";
import HeaderMenu from "../headerMenu";
import BreadCrumb from "../../plugins/Breadcrumb";
import loader from "../../../assets/images/loader.gif";
import Horizontalbar from "../../plugins/Horizontalbar";
import RatingBar from "../../plugins/RatingBar";
import { FaInstagramSquare, FaPhoneAlt } from "react-icons/fa";
import { commonUtils } from "../../CommonUtils";
import { BsGridFill } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import PostCard from "./PostCard";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { followId } from "../../../Redux/Actions/userAction";
import { listType } from "../../../Redux/Actions/userAction";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import { BiSolidCommentDetail } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { MdSwitchAccount, MdEmail } from "react-icons/md";
import { FiUsers, FiUser } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { BsQuestionDiamond } from "react-icons/bs";
import mentionInputStyle from "../../../assets/css/MentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import { useLocation } from "react-router-dom";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { TbSocial } from "react-icons/tb";
import InstagramIcon from "../../plugins/InstagramIcon";
import { AiFillFacebook } from "react-icons/ai";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { IoIosChatboxes } from "react-icons/io";
import setldImg from "../../../assets/images/setld.jpg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaUserTie } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { userAction } from "../../../Redux/Actions/userAction";
import LineChart from "../../plugins/LineChart";
import { MdReviews } from "react-icons/md";
import BusinessScores from "./BusinessScores";
import { TiTick } from "react-icons/ti";
import { FaArrowLeft } from "react-icons/fa6";
import { PiLinkBold } from "react-icons/pi";
import filter from "../../../assets/images/filter.jpg";
import { HiBadgeCheck, HiCheckCircle } from "react-icons/hi";
import CustomModal from "../../plugins/CustomModal";
import { LuActivitySquare } from "react-icons/lu";
import { GrSend } from "react-icons/gr";
import AddPost from "./AddPost";

const Viewuser = (props) => {
  const userIdNew = useSelector((state) => state.user.followid);
  const userTypeNew = useSelector((state) => state.user.followUserType);
  const userProfileId = useSelector((state) => state.user.followProfileId);
  const location = useLocation();
  const userId = location.state?.userId;
  const userType = location.state?.userType;
  const profileId = location.state?.profileId;
  const isTiiptok = location.state?.tiiptok;
  const [currentIndex, setcurrentIndex] = useState(null);
  const [globalRating, setGlobalRating] = useState([]);
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [userreviewList, setUserReviewList] = useState([]);
  const [professionalData, setProfessionalData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [userData, setUserData] = useState({});
  const [showAdditionalReviews, setShowAdditionalReviews] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [isShare, setIsShare] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [reviewId, setreviewId] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [reportCommentErr, setReportCommentErr] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [shareData, setShareData] = useState({});
  const [comment, setComment] = useState("");
  const [revCmnt, setRevCmnt] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [childComment, setchildComment] = useState("");
  const [childcurrentIndex, setchildcurrentIndex] = useState(null);
  const [childrepplycurrentIndex, setchildreplycurrentIndex] = useState([]);
  const [showChildReply, setShowChildReply] = useState(false);
  const [replies, setRevReplies] = useState([]);
  const [reviewSts, setReviewSts] = useState("");
  const [expandedCommentIndex, setExpandedCommentIndex] = useState(-1);
  const [cmtError, setCmtError] = useState("");
  const [cmtErrId, setCmtErrId] = useState(null);
  const [userList, setUserList] = useState("");
  const targetRef = useRef(null);
  const drpRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [eventKey, setEventKey] = useState("Personal");
  const [showData, setShowData] = useState(false);
  const [labels, setLabels] = useState("");
  const [graphRating, setgraphRating] = useState("");
  const [reviewCount, setReviewCount] = useState("");
  const [tiiptok, setTiiptok] = useState(false);
  const [postList, setPostList] = useState([]);
  const [aggregationType, setAggregationType] = useState("monthly");
  const [scoreType, setScoreType] = useState("default");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [roleList, setRoleList] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [showBusnScore, setShowBusnScore] = useState(false);
  const [avgRatng, setAvgRatng] = useState(0);
  const [ratngCount, setRatngCount] = useState(0);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1200);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const toggleFilterSection = () => {
    setShowFilterSection(!showFilterSection);
  };
  const handleTabChange = (key) => {
    setSelectedRoleId("");
    setEventKey(key);
  };

  const readComment = (index) => {
    setExpandedCommentIndex(index === expandedCommentIndex ? -1 : index);
  };
  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const result = rating.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
    setShowPop(!showPop);
  };
  const handleOverlayClose = () => {
    setShowPopover(false);
    setShowPop(false);
  };
  const getRevQnsRating = (value) => {
    const num =
      value.reduce((total, next) => total + next.rating, 0) / value.length;
    return num.toFixed(1);
  };
  useEffect(() => {
    userDetails();
    userLists();
    loadPosts();
  }, [userId || userIdNew]);
  useEffect(() => {
    userDetails();
  }, [props]);
  useEffect(() => {
    profileChartInfo();
  }, [props, aggregationType, scoreType]);
  useEffect(() => {
    setTiiptok(isTiiptok ? isTiiptok : false);
  }, [location]);

  useEffect(() => {
    loadRoleList();
  }, [userreviewList]);
  const loadPosts = async () => {
    const params = {
      userType: userType ? userType : userTypeNew,
      profileId:
        userType != "Personal"
          ? userProfileId
            ? userProfileId
            : profileId
          : null,
    };
    try {
      const response = await window.apiService.postList(params);
      setPostList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const reloadPostListHandler = () => {
      loadPosts();
    };
    window.addEventListener("reloadPostList", reloadPostListHandler);
    return () => {
      window.removeEventListener(
        "reloadPostList", 
        reloadPostListHandler
      );
    };
  }, []);
  const profileChartInfo = async () => {
    const params = {
      userId: userId ? userId : userIdNew,
      userType: userType ? userType : userTypeNew,
      profileId:
        userType != "Personal"
          ? userProfileId
            ? userProfileId
            : profileId
          : null,
      aggregationType: aggregationType,
      scoreType: scoreType,
    };
    try {
      const response = await window.apiService.profileChartInfo(params);
      const { labels, ratings, reviewCounts } =
        commonUtils.convertDataToChartData(
          response.data.data.ratingChart,
          aggregationType,
          scoreType
        );
      setLabels(labels);
      setgraphRating(ratings);
      setReviewCount(reviewCounts);
    } catch { }
  };
  const userDetails = async () => {
    if ((userId || userIdNew) && props) {
      setLoading(true);
      const params = {
        id: userId ? userId : userIdNew,
        userType: userType ? userType : userTypeNew,
        profileId:
          userType != "Personal"
            ? userProfileId
              ? userProfileId
              : profileId
            : null,
        loggedId: props.userId,
        loggedUserType: props.userType,
        loggedProfileId: props.profileId ? props.profileId : null
      };
      try {
        const response = await window.apiService.userProfileView(params);
        setUserData(response.data.data);
        if (response.data.data.business?.showBusinessScore)
          setScoreType("BS")
        else if (response.data.data.business?.showPeopleScore)
          setScoreType("PS")
        else setScoreType("CS")
        // const { labels, ratings, reviewCounts } = convertDataToChartData(
        //   response.data.data.ratingChart
        // );
        // setLabels(labels);
        // setgraphRating(ratings);
        // setReviewCount(reviewCounts);
        if (
          response.data.data.professional == undefined &&
          response.data.data.userType == "Professional"
        )
          setShowData(true);
        else setShowData(false);
        // setUserReviewList(
        //   response.data.data.reviewList.filter(
        //     (item) =>
        //       item.reviewedBy != undefined &&
        //       item.reviewedByProfessional == undefined &&
        //       item.reviewedByBusiness == undefined
        //   )
        // );
        setUserReviewList(response.data.data.reviewList);
        // setProfessionalData(
        //   response.data.data.reviewList.filter(
        //     (item) => item.reviewedByProfessional != undefined
        //   )
        // );
        // setBusinessData(
        //   response.data.data.reviewList.filter(
        //     (item) => item.reviewedByBusiness != undefined
        //   )
        // );
        const starResponse =
          response.data.data.userType == "Professional"
            ? response.data.data.professional.starRating
            : response.data.data.userType == "Business"
              ? response.data.data.business.starRating
              : response.data.data.starRating;
        const result = starResponse.map((starData) => ({
          label: starData.star,
          value: starData.totalReviewCount,
          userRatings: starData.userRatings,
        }));
        result.sort((a, b) => {
          const numA = parseInt(a.label.split(" ")[0]);
          const numB = parseInt(b.label.split(" ")[0]);
          return numB - numA;
        });
        setGlobalRating(result);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Error Occured");
      }
    }
  };
  const handleShare = (data) => {
    setIsShare(true);
    setShareData(data);
  };
  const togglechildcomment = (id) => {
    setchildComment("");
    if (childrepplycurrentIndex == id) {
      setchildreplycurrentIndex(null);
    } else {
      setchildreplycurrentIndex(id);
    }
  };
  const loadRevComments = async (revId) => {
    try {
      const response = await window.apiService.reviewCmtList({ id: revId });
      setRevCmnt(response.data.recordInfo.reviewComments);
      setRevReplies(response.data.recordInfo.reviewReplies);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const viewRevComments = (data, replies, sts) => {
    setShowReply(!showReply);
    setReviewSts(sts);
    setShowChildReply(false);
    loadRevComments(data[0].reviewId);
    setcurrentIndex(null);
    setchildreplycurrentIndex(null);
  };
  const viewChildComments = (data, id, revId) => {
    setcurrentIndex(null);
    setShowChildReply(!showChildReply);
    if (childcurrentIndex == id) {
      setchildcurrentIndex(null);
    } else {
      setchildcurrentIndex(id);
    }
    loadRevComments(revId);
  };
  const handlerplylikechange = (id, revId) => {
    console.log("revId", revId);
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 1,
    });
  };
  const handlerplydislikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 0,
    });
  };
  const rplylikecntDecrement = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: -1,
    });
  };
  const updaterplyLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addCommentLike(likeParams);
      if (response.data.status == "OK") {
        loadRevComments(likeParams.reviewId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handlelikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 1 });
  };
  const handledislikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 0 });
  };
  const updateLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addLike(likeParams);
      if (response.data.status == "OK") {
        userDetails();
        setShowAdditionalReviews(false);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handleReport = (event) => {
    setReportMsg(event.target.value);
    if (event.target.value) {
      setReportCommentErr("");
    }
  };
  const likecntDecrement = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: -1 });
  };
  const sendReport = (id) => {
    setreviewId(id);
    setShowReport(true);
  };
  const closeReportModal = () => {
    setReportCommentErr("");
    setShowReport(false);
  };
  const submitReport = async () => {
    if (reportMsg.trim().length != 0) {
      if (reviewId) {
        try {
          const params = {
            reviewId: reviewId,
            reporterId: userId,
            additionalInfo: reportMsg,
          };
          const response = await window.apiService.addReport(params);
          if (response.data.status == "OK") {
            toast.success("Report added successfully");
            setShowReport(false);
            userDetails();
            setShowAdditionalReviews(false);
          }
        } catch (error) {
          toast.error("Error Occured");
        }
      }
    } else {
      setReportCommentErr("Report Comment is required.");
    }
  };
  const getSocialMediaShareInfo = () => {
    // return "Recruitment is going on for Click the link to Find Jobs and Get Hired";
  };
  const getSocialMediaHashTag = (title, code) => {
    // return "hzzshhdz";
  };

  const togglecomment = (id) => {
    setComment("");
    setchildcurrentIndex(null);
    setShowChildReply(false);
    if (currentIndex == id) {
      setcurrentIndex(null);
    } else {
      setcurrentIndex(id);
    }
  };
  const postComment = async (id, revId, type) => {
    if (comment.trim().length !== 0 || childComment.trim().length !== 0) {
      let params = "";
      if (type == "child") {
        params = {
          userId: props.userId,
          commentText: childComment,
          reviewId: revId,
          parentCommentId: id,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      } else {
        params = {
          userId: props.userId,
          commentText: comment,
          reviewId: id,
          parentCommentId: null,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      }
      try {
        const response = await window.apiService.addComment(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setComment("");
          setchildComment("");
          loadRevComments(params.reviewId);
          userDetails();
          togglecomment(response.data.id);
          setmentionuserIds([]);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    } else {
      setCmtError("Comment text is required");
      setCmtErrId(id);
    }
  };
  const handleReviewClick = (
    userId,
    professionalList,
    professionalId,
    businessId,
    businessName,
    viewUserType,
    professionType,
    professionTypeId
  ) => {
    navigate("/dashboard/viewuser/postreview", {
      state: {
        userId,
        professionalList,
        professionalId,
        businessId,
        businessName,
        viewUserType,
        professionType,
        professionTypeId,
      },
    });
  };
  const handleNameClick = (
    userId,
    reviewerUserType,
    reviewedByProfessional,
    reviewedByBusiness,
    self
  ) => {
    clear();
    const profileId = reviewedByProfessional || reviewedByBusiness;
    dispatch(followId(userId, reviewerUserType, profileId));
    navigate("/dashboard/viewuser", {
      state: {
        userId,
        profileId,
        userType: reviewerUserType,
      },
    });
    if (self == "1") userDetails();
  };
  const handleShowIcons = () => {
    setShowIcons(true);
  };
  const userLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != props.userId &&
          row.userId.split("-")[0] != props.userId &&
          row.userId.split("&")[0] != props.userId
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };
  const changeCmtText = (value, type) => {
    commonUtils.changeCmtText(
      value,
      type,
      setmentionuserIds,
      setComment,
      setchildComment
    );
  };
  const showAddPost = userData?.preferenceList?.some((item) => {
    if (item.preferenceName === "view_post_comment" && item.value === true) {
      return true;
    } else {
      return false;
    }
  });
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let userId = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [userId, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [userId, profId] = userIdPart.split("-");
      }
      if (name && userId) {
        if (userId !== props.userId) {
          dispatch(followId(userId));
          navigate("/dashboard/viewuser", {
            state: {
              userId,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word;
    });
  }
  function rederPostCards(posts) {
    return (
      <>
        <div className="row col-md-12 fixed-div justify-content-center">
          <div className="w-500 d-flex">
            <FaArrowLeft
              style={{ marginTop: "5px" }}
              className=" gray_clr font-weight-500 c_pointer"
              title="Back"
              onClick={() => setTiiptok(false)}
            />
          </div>
        </div>
        {userData && showAddPost && userData.frdReqStatus == "accepted" && <div className="d-flex justify-content-center" style={{marginBottom: "4px"}}>
        <AddPost userData={userData} props={props}/>
        </div>}
        <div className="row col-md-12 p-0 d-align mx-0 flx_column">
          {posts && posts
            .filter((post) => post.postedTo == userId).length > 0 ? (
            posts
              .filter((post) => post.postedTo == userId)
              .map((post, idx) => (
                <PostCard
                  postInfo={post}
                  key={idx}
                  userId={props.userId}
                  loadPostList={loadPosts}
                />
              ))
          ) : (
            <div className="row d-align mx-0 pb-4">
              <div className="no-data font-weight-600 w-100 text-center fz_14">
                Sorry, there is no data to be displayed
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  function renderReviewCards(reviewData) {
    return reviewData.length > 0 && !loading ? (
      (selectedRoleId ? filteredList : reviewData).map((data, i) => (
        <Card className="meCard" key={data.id}>
          <Card.Body
            className={`${data.status === "new" ? "rev_padg" : "rev_padg"} ${showAdditionalReviews && selectedReviewId === data.reviewedBy
              ? "card_drp"
              : ""
              } ${data.status === "reported" || data.status == "blocked"
                ? "bg_gray rev_padg"
                : ""
              }`}
          >
            <div className="text-end dot_3">
              {data.status == "reported" && (
                <span className="uder_Rev">Under Review</span>
              )}
              {data.status == "blocked" && (
                <span className="uder_Rev">Blocked</span>
              )}
            </div>
            <Row>
              <Col sm={12} md={6} lg={3} xl={2} className="text-center">
                <div className="border_btm h-72">
                  <img
                    className="meImg_review"
                    src={
                      data.reviewerImagePath ? data.reviewerImagePath : profile
                    }
                  />
                </div>
                {data.status != "blocked" &&
                  data.status != "reported" &&
                  data.reviewedBy != props.userId &&
                  userData.id != data.reviewedBy ? (
                  <Card.Text
                    title={data.businessTypeName}
                    className={`mb-0 card_txt name_blue ${data.reviewerUserType == "Personal" ? "" : "ln_he1"
                      } c_pointer `}
                    onClick={() =>
                      handleNameClick(
                        data.reviewedBy,
                        data.reviewerUserType,
                        data.reviewedByProfessional,
                        data.reviewedByBusiness,
                        "0"
                      )
                    }
                  >
                    {data.reviewerUserType === "Business"
                      ? data.busnShortName
                        ? data.busnShortName
                        : data.businessTypeName
                      : data.reviewerName}
                  </Card.Text>
                ) : (
                  <Card.Text className="mb2 card_txt">
                    {data.reviewerUserType === "Business"
                      ? data.busnShortName
                        ? data.busnShortName
                        : data.businessTypeName
                      : data.reviewerName}
                  </Card.Text>
                )}
                {data.profTypeName && (
                  <span className="fz_14">{"(" + data.profTypeName + ")"}</span>
                )}
                {/* {data.businessTypeName && (
                  <span className="fz_14">
                    {"(" + data.businessTypeName + ")"}
                  </span>
                )} */}
                <div
                  className=" d-flex justify-content-center align-items-center fs_12"
                  style={{ height: "20px" }}
                >
                  <div className="d-flex align-items-center">
                    {data.averageReviewScore > 0.0 ? (
                      <label className="font-weight-bold mt_14">
                        {data.averageReviewScore.toFixed(1)}
                      </label>
                    ) : (
                      ""
                    )}
                    <StarRating
                      rating={data.averageReviewCount}
                      disabled={true}
                      customClass="special-star"
                    />
                    <span>({data.totalReviewCount})</span>
                    <OverlayTrigger
                      trigger="click"
                      key={data.id}
                      placement="bottom"
                      overlay={
                        <Popover
                          id={`popover-${data.id}`}
                          className="pop_rating"
                        >
                          <div className="drp_rating">
                            <StarRating rating={avgCount} disabled={true} />
                            <span>{avgCount} out of 5</span>{" "}
                          </div>
                          {revGlobalRtng.map((item, index) => (
                            <Row key={index}>
                              <Col sm={12} md={3} lg={3} xl={2} className="p-0">
                                <span className="prog_span">{item.label}</span>{" "}
                              </Col>
                              <Col sm={12} md={8} lg={8} xl={7} className="p-0">
                                <Horizontalbar dataSet={item.value} />
                              </Col>
                              <Col
                                sm={12}
                                md={3}
                                lg={3}
                                xl={2}
                                className="p-0 prog_span"
                                style={{ marginLeft: "10px" }}
                              >
                                <span className="tooltip">
                                  <span
                                    className="c_pointer"
                                    onMouseEnter={() =>
                                      handleMouseEnter(item.userRatings)
                                    }
                                  >
                                    {item.value}
                                    {item.value > 1 ? (
                                      <FiUsers style={{ marginLeft: "6px" }} />
                                    ) : (
                                      <FiUser style={{ marginLeft: "6px" }} />
                                    )}
                                  </span>
                                  {commonUtils.ratingTooltip(currentUsers)}
                                </span>
                              </Col>
                            </Row>
                          ))}
                        </Popover>
                      }
                      rootClose
                    >
                      {data.status != "blocked" ? (
                        <span
                          className="d-flex align-items-center"
                          onClick={() =>
                            handleArrowClick(
                              data.starRating,
                              data.averageReviewCount
                            )
                          }
                        >
                          <IoIosArrowDown className="clr_gray c_pointer" />
                        </span>
                      ) : (
                        <span className="d-flex align-items-center"></span>
                      )}
                    </OverlayTrigger>
                    {data.status == "blocked" && (
                      <IoIosArrowDown className="clr_gray" />
                    )}
                  </div>
                </div>
              </Col>
              <Col className="card_bdr_left m-0" sm={12} md={6} lg={3} xl={3}>
                <Card.Text className="mb-0" title={data.comment}>
                  <div
                    className={` ${expandedCommentIndex === i ? "show-full" : ""
                      }`}
                    title={data.comment}
                  >
                    {data.comment.length >= 40 && expandedCommentIndex !== i
                      ? textWithLinks(data.comment.substring(0, 40))
                        .map((word, index) => (
                          <span key={index}>{word} </span>
                        ))
                        .concat("...")
                      : textWithLinks(data.comment).map((word, index) => (
                        <span key={index}>{word} </span>
                      ))}
                  </div>

                  {data.comment.length >= 40 && (
                    <button
                      disabled={
                        data.status == "blocked" || data.status == "reported"
                          ? true
                          : false
                      }
                      onClick={() => readComment(i)}
                      className={
                        data.status == "blocked" || data.status == "reported"
                          ? "read-more-dis"
                          : "read-more-button"
                      }
                    >
                      {expandedCommentIndex === i ? "Read less" : "Read more"}
                    </button>
                  )}
                </Card.Text>
                {data.reviewComments.length != 0 && (
                  <span
                    className="rply c_pointer"
                    title="view review comment"
                    onClick={() =>
                      viewRevComments(
                        data.reviewComments,
                        data.reviewReplies,
                        data.status
                      )
                    }
                  >
                    {data.reviewComments.length === 1
                      ? `${data.reviewComments.length} Reply `
                      : `${data.reviewComments.length} Replies `}
                  </span>
                )}
                <span>
                  {data.status == "reported" ||
                    data.status == "blocked" ||
                    data.reviewedBy === props.userId ? (
                    <BiSolidCommentDetail
                      className="cmt_icn clr_gray"
                      title="Add comment"
                    />
                  ) : (
                    <BiSolidCommentDetail
                      className="cmt_icn c_pointer"
                      title="Add comment"
                      onClick={() => togglecomment(data.id)}
                    />
                  )}
                  {currentIndex == data.id ? (
                    <Row>
                      <div className="m-2 position-relative">
                        {cmtError && cmtErrId == data.id ? (
                          <div className="text-danger fz-12">{cmtError}</div>
                        ) : (
                          ""
                        )}
                        <MentionsInput
                          style={mentionInputStyle}
                          className="form-control me_cmt_box"
                          maxLength={288}
                          onChange={(e) => {
                            changeCmtText(e.target.value, "parent");
                          }}
                          value={comment}
                          placeholder="type someting..."
                        >
                          <Mention
                            style={{ backgroundColor: "#efebe3" }}
                            trigger="@"
                            data={users}
                          />
                        </MentionsInput>
                        {/* <textarea style={{marginLeft: '-10px'}} className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setComment(e.target.value)} value={comment}/> */}
                        <button
                          className="btn btn-primary cmt_btnn"
                          onClick={() => postComment(data.id, 0, "parent")}
                        >
                          Post
                        </button>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </span>
              </Col>
              <Col sm={12} md={6} lg={3} xl={3}>
                {false && (
                  <div className="d-flex" style={{ gap: "16px" }}>
                    {data.status === "reported" ||
                      data.status == "blocked" ||
                      data.reviewedBy === props.userId ? (
                      <p className="dis_rep_icon" title="Report">
                        Report
                      </p>
                    ) : (
                      <p
                        className="rep_icon"
                        title="Report"
                        onClick={() => {
                          sendReport(data.id);
                        }}
                      >
                        Report
                      </p>
                    )}
                    <div className="div_line"></div>
                    {data.status === "reported" ||
                      data.status == "blocked" ||
                      data.reviewedBy === props.userId ? (
                      <BsShareFill className="dis_share_icon" title="Share" />
                    ) : (
                      <BsShareFill
                        className="share_icon"
                        title="Share"
                        onClick={() => handleShare(data)}
                      />
                    )}
                  </div>
                )}
                <Card.Text className="mb-0">
                  <span className="txt_rev">Reviewed as:</span>{" "}
                  <span className="fts_15">{data.userRole}</span>
                </Card.Text>
                <Card.Text className="mb-0">
                  <span className="txt_rev">Date of Review:</span>
                  <span className="fts_15">
                    {" "}
                    {moment(data.createdAt).format("DD-MMM-YYYY")}
                  </span>
                </Card.Text>
                <Card.Text className="mb2">
                  <div className="d-flex">
                    {data.rating != 0 ? (
                      <label className="font-weight-bold mar-10">
                        {/* {getRevQnsRating(data.reviewQuestion)}&nbsp; */}
                        {data.rating.toFixed(1)}
                      </label>
                    ) : (
                      ""
                    )}
                    <StarRating
                      rating={data.rating}
                      disabled={true}
                      customClass="special-star"
                    />
                    {data.reviewQuestion.length != 0 && (
                      <OverlayTrigger
                        trigger="click"
                        key={data.id}
                        placement="bottom"
                        overlay={
                          <Popover
                            id={`popover-${data.id}`}
                            className="pop_rating"
                          >
                            <div className="drp_rating"></div>
                            {data.reviewQuestion.length === 0 ? (
                              <div>No questions found</div>
                            ) : (
                              data.reviewQuestion.map((item, index) => (
                                <Row className="d-flex" key={index}>
                                  <Col
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}
                                    className="cus-col p-0 prog_span d-flex align-items-center"
                                  >
                                    {index + 1}.
                                  </Col>
                                  <Col
                                    sm={5}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    className="cus-col-ques p-0 d-flex align-items-center"
                                  >
                                    <span className="prog_span">
                                      {item.question}
                                    </span>
                                  </Col>
                                  <Col
                                    sm={6}
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    className="cus-col-ratng p-0"
                                  >
                                    <StarRating
                                      rating={item.rating}
                                      disabled={true}
                                      customClass="ques-star"
                                    />
                                  </Col>
                                </Row>
                              ))
                            )}
                          </Popover>
                        }
                        rootClose
                      >
                        <span
                          style={{ marginTop: "5px", marginLeft: "5px" }}
                          className="d-flex align-items-center c_pointer"
                          onClick={() =>
                            handleArrowClick(
                              data.starRating,
                              data.averageReviewCount
                            )
                          }
                        >
                          <BsQuestionDiamond className="clr_gray" />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </Card.Text>
              </Col>
              <Col sm={12} md={6} lg={3} xl={3}>
                {data.status === "reported" ||
                  data.status == "blocked" ||
                  data.reviewedBy === props.userId ? (
                  <RatingBar
                    disabled={true}
                    likecount={data.likes}
                    dislikecount={data.dislikes}
                    liked={data.liked}
                    handlelike={() => handlelikechange(data.id, data.userId)}
                    handledislike={() =>
                      handledislikechange(data.id, data.userId)
                    }
                    handleRmvlike={() => likecntDecrement(data.id, data.userId)}
                    labelVAlue={data.score}
                  />
                ) : (
                  <RatingBar
                    disabled={
                      data.status == "reported" || data.status == "blocked"
                        ? true
                        : false
                    }
                    likecount={data.likes}
                    dislikecount={data.dislikes}
                    liked={data.liked}
                    handlelike={() => handlelikechange(data.id, data.userId)}
                    handledislike={() =>
                      handledislikechange(data.id, data.userId)
                    }
                    handleRmvlike={() => likecntDecrement(data.id, data.userId)}
                    labelVAlue={data.score}
                  />
                )}
              </Col>
              {/* <Col sm={12} md={2} lg={1} xl={1} className="more_rev c_pointer">
                {data.count > 1 && (
                  <span
                    onClick={() => {
                      navigate("/dashboard/viewuser/more_reviews", {
                        state: { userIdMe: data, logId: userData.id },
                      });
                    }}
                  >
                    More...{" "}
                  </span>
                )}
              </Col> */}
              {/* <Col sm={12} md={1} lg={1} xl={1} className='more_rev c_pointer'>
                                    {data.count > 1 && (
                                    <button className="view_btn" onClick={() => {navigate('/dashboard/more_reviews', { state: { userIdMe: data } });}}>View </button>
                                    )} 
                                    </Col> */}
            </Row>
          </Card.Body>
        </Card>
      ))
    ) : loading ? (
      <div className="loader-center">
        <img className="loader-image" src={loader} alt="Loading..." />
      </div>
    ) : (
      <div className="row col-md-12 p-0 d-align mx-0">
        <div className="no-data w-100 font-weight-600 text-center fz_14">
          Sorry, there is no data to be displayed
        </div>
      </div>
    );
  }
  const loadRoleList = async () => {
    try {
      const response = await window.apiService.loadUserRoles();
      const userReviewRoleIds = new Set(userreviewList.map(review => review.roleId));
      const filteredRoleList = response.data.recordInfo.filter(role => userReviewRoleIds.has(role.id));
      setRoleList(filteredRoleList);
    } catch (error) {
      console.log(error);
    }
  };
  const clear = () => {
    setSelectedRoleId("");
    setAvgRatng(0);
  }
  const handleUserRole = (event) => {
    const roleId = event.target.value;
    setSelectedRoleId(roleId);
    if (roleId) {
      const filtered = userreviewList.filter(
        (review) => review.roleId === parseInt(roleId, 10)
      );
      setFilteredList(filtered);
      if (filtered.length > 0) {
        const totalRating = filtered.reduce((acc, review) => acc + (review.rating || 0), 0);
        const averageRating = totalRating / filtered.length;
        setAvgRatng(averageRating);
        setRatngCount(filtered.length);
      } else {
        console.log("No reviews available for the selected role.");
      }
    } else {
      setFilteredList(userreviewList);
    }
  };

  const sendRequest = async () => {
    if (userData) {
      const params = {
        senderId: props.userId,
        senderUserType: props.userType,
        senderTypeId: props.profileId ? props.profileId : null,
        receiverId: userData.id,
        receiverUserType: userData.userType,
        receiverTypeId: userData.business?.id ? userData.business?.id : userData.professional?.id ? userData.professional?.id : null,
        status: "pending",
        isRead: false
      };
      try {
        const response = await window.apiService.sendRequest(params);
        if (response.data.status === 'OK') {
          toast.success(response.data.message);
          userDetails();
        }
      } catch (error) {
        toast.error("Error Occurred");
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://18.223.205.99/" />
        <meta property="og:description" content={shareData.reviewerName} />
        <meta property="og:image" content={logoImg} />
      </Helmet>
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          <BreadCrumb />
          {!showData ? (
            <Card className="shade">
              <Card className="shadow">
                <Card.Body style={{ padding: "13px 0px" }} className="me_card">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    {/* <Row className="justify-content-center"> */}
                    {/* <Col sm={12} md={12} lg={12} xl={12}>
                        <div className="social_resDiv">
                          <TbSocial onClick={() => setShowIcons(true)} />
                        </div>
                      </Col> */}
                    <Row>
                      {userData.userType === "Business" &&
                        (userData.business?.showBusinessScore || userData.business?.showPeopleScore || userData.business?.showCultureScore) && (
                          <><div className="bs_icon" >
                            <div className="d-flex justify-content-end">
                              <LuActivitySquare className="fz_22" onClick={() => setShowBusnScore(true)} />
                            </div>
                          </div>
                            {isWideScreen ? <BusinessScores
                              businessScore={userData.business?.showBusinessScore}
                              cultureScore={userData.business?.showCultureScore}
                              peopleScore={userData.business?.showPeopleScore}
                              userData={userData}
                              loadChart={() => profileChartInfo()}
                              setScoreType={setScoreType}
                              scoreType={scoreType}
                            /> : ''}
                          </>
                        )}
                      <Col
                        className={`pl-0 ${userData.userType === "Business" &&
                          (userData.business?.showBusinessScore || userData.business?.showPeopleScore || userData.business?.showCultureScore)
                          ? "dash_prof"
                          : ""
                          }`}
                        sm={12}
                        md={6}
                        lg={
                          userData.userType === "Business" &&
                            (userData.business?.showBusinessScore || userData.business?.showPeopleScore || userData.business?.showCultureScore)
                            && isWideScreen ? 3
                            : 4
                        }
                        xl={
                          userData.userType === "Business" &&
                            (userData.business?.showBusinessScore || userData.business?.showPeopleScore || userData.business?.showCultureScore)
                            && isWideScreen ? 3
                            : 4
                        }
                      >
                        <div className="mecrd_bdr_rt">
                          {userData.id != props.userId && (
                            <div className="mt-2 social_div">
                              <MdReviews
                                title="Add Review"
                                className="rev_clr fz_30 mr-4 c_pointer"
                                onClick={() =>
                                  handleReviewClick(
                                    userData?.id,
                                    userData?.professionalList,
                                    userData?.professional?.id,
                                    userData?.business?.id,
                                    userData?.business?.companyName,
                                    userData?.userType,
                                    userData?.professional?.professionType,
                                    userData?.professional?.professionTypeId
                                  )
                                }
                              />
                              <a
                                className="c_pointer"
                                onClick={() => {
                                  navigate("/inbox/3", {
                                    state: { userId: userId },
                                  });
                                }}
                              >
                                <div className="d-flex mr-2">
                                  <img
                                    title="Chat"
                                    src={chatImg}
                                    alt="chat"
                                    height={35}
                                    width={35}
                                  />
                                </div>
                              </a>
                              <div><BsGridFill
                                title="Tiiptok"
                                className="fz-22 rev_clr mr-3 c_pointer"
                                onClick={() => setTiiptok(true)}
                              /></div>
                              {userData.userType != "Professional" && userData.isKycVerified && props.isKycVerified && userData.frdReqStatus != "pending" && userData.frdReqStatus != "accepted" ? <div>
                                <GrSend title="Send request"
                                  className="fz-22 mr-3 c_pointer req" onClick={sendRequest} /></div> :
                                userData.userType != "Professional" ? <div>
                                  <GrSend title={userData.frdReqStatus == "pending" ? "Requested" : userData.frdReqStatus == "accepted" ? "Request Accepted" : !userData.isKycVerified ? "KYC not verified" : ""}
                                    className="fz-22 mr-3 gray_clr" style={{ marginTop: "10px" }} /></div> : ""}
                              {/* <IoIosChatboxes
                                  className="icnCht"
                                  title="Message"
                                  onClick={() => {
                                    navigate("/inbox/3", {
                                      state: { userId: userId },
                                    });
                                  }}
                                /> */}
                            </div>
                          )}
                          <div className="d-flex justify-content-center">
                            <div
                              className=" d-flex align-items-center"
                              style={{
                                position: "relative",
                                display: "inline-block",
                                margin: " 0px 18px",
                              }}
                            >
                              <img
                                className="meImg_profile"
                                src={
                                  userData.userType == "Professional"
                                    ? userData.professional?.imagePath
                                      ? userData.professional.imagePath
                                      : profile
                                    : userData.userType == "Business"
                                      ? userData.business?.logo
                                        ? userData.business.logo
                                        : profile
                                      : userData.imagePath
                                        ? userData.imagePath
                                        : profile
                                }
                              />
                            </div>
                          </div>
                          {userData.userType !== "Business" && (
                            <div
                              className=" d-flex justify-content-center align-items-center mt-2"
                              style={{ height: "20px" }}
                            >
                              <div className="d-flex">
                                {userData.userType == "Professional" ? (
                                  userData.professional?.averageReviewScore >
                                    0.0 ? (
                                    <label className="font-weight-bold mt_14">
                                      {userData.professional?.averageReviewScore.toFixed(
                                        1
                                      )}
                                    </label>
                                  ) : (
                                    ""
                                  )
                                ) : userData.userType == "Business" ? (
                                  userData.business?.averageReviewScore >
                                    0.0 ? (
                                    <label className="font-weight-bold mt_14">
                                      {userData.business?.averageReviewScore.toFixed(
                                        1
                                      )}
                                    </label>
                                  ) : (
                                    ""
                                  )
                                ) : userData.averageReviewScore > 0.0 ? (
                                  <label className="font-weight-bold mt_14">
                                    {userData.averageReviewScore.toFixed(1)}
                                  </label>
                                ) : (
                                  ""
                                )}
                                <StarRating
                                  rating={
                                    userData.userType == "Professional" ||
                                      userData.userType == "professional"
                                      ? userData.professional
                                        ?.averageReviewCount
                                      : userData.userType == "Business"
                                        ? userData.business?.averageReviewCount
                                        : userData.averageReviewCount
                                  }
                                  disabled={true}
                                />
                              </div>
                              <span>
                                (
                                {userData.userType == "Professional"
                                  ? userData.professional?.totalReviewCount
                                  : userData.userType == "Business"
                                    ? userData.business?.totalReviewCount
                                    : userData.totalReviewCount}
                                )
                              </span>
                              <span
                                className="c_pointer"
                                onClick={handleButtonClick}
                                ref={targetRef}
                              >
                                <IoIosArrowDown />
                              </span>
                            </div>
                          )}
                          <div className=" d-flex justify-content-center align-items-center mt-2">
                            <div className="disply_foll">
                              <div className="circle-background  text-center  ">
                                {userData.userType == "Professional"
                                  ? userData.professional
                                    ?.professionalFollowerCount
                                  : userData.userType == "Business"
                                    ? userData.business?.businessFollowerCount
                                    : userData.followersCount}
                              </div>
                              <div className="me_text">Followers</div>
                            </div>
                            <div className="disply_foll border-right-0">
                              <div className="circle-background  text-center">
                                {userData.userType == "Professional"
                                  ? userData.professional
                                    ?.professionalFollowingCount
                                  : userData.userType == "Business"
                                    ? userData.business?.businessFollowingCount
                                    : userData.followingCount}
                              </div>
                              <div className="me_text">Following</div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        md={6}
                        lg={
                          userData.userType === "Business" &&
                            (userData.business?.showBusinessScore || userData.business?.showPeopleScore || userData.business?.showCultureScore)
                            ? 4
                            : 4
                        }
                        xl={4}
                      >
                        <div className="me_center_div">
                          {/* <div className="d-flex"> */}
                          <Card.Text className="mb-0 fz-22 alert-link">
                            {userData.userType == "Business"
                              ? userData.business.companyName
                              : userData.name}
                            {userData.isKycVerified &&
                              userData.userType == "Personal" ? (
                              <HiBadgeCheck
                                style={{
                                  fontSize: "22px",
                                  color: "green",
                                  cursor: "pointer",
                                  marginLeft: "5px"
                                }}
                                title="KYC Verified"
                                data-toggle="tooltip"
                                data-placement="top"
                              />
                            ) : (
                              <></>
                            )}

                          </Card.Text>
                          {/* </div> */}
                          <div className="contact-item">
                            {userData.userType ? <MdSwitchAccount /> : ""}
                            &nbsp;
                            <span className="me_text">
                              {userData.userType
                                ? userData.userType == "Professional" ||
                                  userData.userType == "professional"
                                  ? userData.userType +
                                  " (" +
                                  userData.professional?.professionType +
                                  ")"
                                  : userData.userType
                                : ""}
                            </span>
                          </div>
                          <div className="contact-item">
                            {userData.userType == "Professional" ? (
                              <div className="">
                                {userData.professional.location ? (
                                  <>
                                    <HiLocationMarker />
                                    &nbsp;
                                    <span className="me_text">
                                      {userData.professional.location}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : userData.userType == "Business" ? (
                              <div className="">
                                {userData.business.city ||
                                  userData.business.state ? (
                                  <>
                                    <HiLocationMarker />
                                    &nbsp;
                                    <span className="me_text">
                                      {userData.business.city
                                        ? userData.business.city
                                        : ""}
                                      {userData.business.city &&
                                        userData.business.state
                                        ? ","
                                        : ""}
                                      {userData.business.state
                                        ? userData.business.state
                                        : ""}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <div className="">
                                {userData.city || userData.state ? (
                                  <>
                                    <HiLocationMarker />
                                    &nbsp;
                                    <span className="me_text">
                                      {userData.city ? userData.city : ""}
                                      {userData.city && userData.state
                                        ? ","
                                        : ""}{" "}
                                      {userData.state ? userData.state : ""}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>

                          <div className="">
                            <div className="contact-item">
                              {userData && userData.phone && (
                                <>
                                  {userData.phone && <FaPhoneAlt />} &nbsp;
                                  <span className="me_text">
                                    {userData.phone
                                      ? userData.isPublic
                                        ? userData.phone
                                        : "x".repeat(
                                          Math.max(
                                            0,
                                            userData.phone.length - 4
                                          )
                                        ) + userData.phone.slice(-4)
                                      : "-"}
                                  </span>
                                </>
                              )}
                            </div>
                            {userData.business?.website ? <div className="contact-item">
                              <PiLinkBold /> &nbsp;
                              <a
                                href={userData.business?.website}
                                target="_blank"
                                className="a_social"
                              ><span className="me_text name_blue" title={userData.business?.website}> {userData.business?.website.length >= 30 ?
                                userData.business?.website.substring(0, 30).concat("...") : userData.business?.website}</span></a>
                            </div> : ''}
                            <div className="contact-item">
                              {userData && userData.email && (
                                <>
                                  <MdEmail /> &nbsp;
                                  <span className="me_text">
                                    {userData.isPublic
                                      ? `(${userData.email})`
                                      : `(${userData.email.slice(
                                        0,
                                        4
                                      )}${"x".repeat(
                                        Math.max(
                                          0,
                                          userData.email.length - 4
                                        )
                                      )})`}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          <div
                            className="me_text"
                          // style={{ width: "390px" }}
                          >
                            {userData.userType == "Professional"
                              ? userData.professional.bio
                              : userData.bio}
                          </div>
                          {userData.isPublic && (
                            <div className="mt-2">
                              {userData.facebookUrl && (
                                <a
                                  href={userData.facebookUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <AiFillFacebook
                                    className="c_pointer social_icon_f mr-2"
                                    size={28}
                                  />
                                </a>
                              )}
                              {userData.linkedinUrl && (
                                <a
                                  href={userData.linkedinUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <FaLinkedin
                                    size={25}
                                    className="social_icon_li mr-2"
                                  />
                                </a>
                              )}
                              {userData.instagramUrl && (
                                <a
                                  href={userData.instagramUrl}
                                  target="_blank"
                                  className="a_social mr-2"
                                >
                                  <InstagramIcon />
                                </a>
                              )}
                              {userData.twitterUrl && (
                                <a
                                  href={userData.twitterUrl}
                                  target="_blank"
                                  className="a_social"
                                >
                                  <FaSquareXTwitter
                                    size={25}
                                    className="rounded-0 social_icon mr-2"
                                  />
                                </a>
                              )}
                              {userData.facebookUrl ||
                                userData.linkedinUrl ||
                                userData.instagramUrl ||
                                userData.twitterUrl ? (
                                <a
                                  href="https://setld.app/"
                                  target="_blank"
                                  className="a_social mr-2"
                                >
                                  <img
                                    src={setldImg}
                                    height={22}
                                    width={22}
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                        <Overlay
                          show={showPopover}
                          target={targetRef.current}
                          placement="bottom"
                          container={document.body}
                          onHide={handleOverlayClose}
                          rootClose
                        >
                          <Popover id="popover" className="pop_rating">
                            <div className="drp_rating">
                              <StarRating
                                rating={
                                  userData.userType == "Professional"
                                    ? userData.professional
                                      ?.averageReviewCount
                                    : userData.userType == "Business"
                                      ? userData.business?.averageReviewCount
                                      : userData.averageReviewCount
                                }
                                disabled={true}
                              />
                              <span>
                                {userData.userType == "Professional"
                                  ? userData.professional?.averageReviewCount
                                  : userData.userType == "Business"
                                    ? userData.business?.averageReviewCount
                                    : userData.averageReviewCount}{" "}
                                out of 5
                              </span>{" "}
                            </div>
                            {globalRating.map((item, index) => (
                              <Row>
                                <Col
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={2}
                                  className="p-0"
                                >
                                  <span className="prog_span">
                                    {item.label}
                                  </span>{" "}
                                </Col>
                                <Col
                                  sm={12}
                                  md={8}
                                  lg={8}
                                  xl={7}
                                  className="p-0"
                                >
                                  <Horizontalbar dataSet={item.value} />
                                </Col>
                                <Col
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={2}
                                  className="p-0 prog_span"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <span className="tooltip">
                                    <span
                                      className="c_pointer"
                                      onMouseEnter={() =>
                                        handleMouseEnter(item.userRatings)
                                      }
                                    >
                                      {item.value}
                                      {item.value > 1 ? (
                                        <FiUsers
                                          style={{ marginLeft: "6px" }}
                                        />
                                      ) : (
                                        <FiUser
                                          style={{ marginLeft: "6px" }}
                                        />
                                      )}
                                    </span>
                                    {commonUtils.ratingTooltip(currentUsers)}
                                  </span>
                                </Col>
                              </Row>
                            ))}
                          </Popover>
                        </Overlay>
                      </Col>
                      <Col sm={12} md={12} lg={4} xl={4} className="pr-0">
                        <LineChart
                          data={graphRating}
                          labels={labels}
                          additionalData={reviewCount}
                        />
                        <div className="d-flex justify-content-end">
                          <Col sm={12} md={6} lg={6} xl={4}>
                            <select
                              className="rle_place form-control input_select fz-12"
                              value={aggregationType}
                              onChange={(e) =>
                                setAggregationType(e.target.value)
                              }
                            >
                              <option value="yearly">Yearly</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    {/* </Row> */}
                  </Col>
                </Card.Body>
              </Card>
              {!tiiptok && <div className="pro_tab_div">
                <div style={{ position: "relative" }}>
                  <Tabs
                    activeKey={eventKey}
                    defaultActiveKey="Personal"
                    id="profile_tab"
                    className="mb-2"
                    onSelect={(key) => {
                      handleTabChange(key);
                    }}
                  >
                    <Tab
                      title={
                        <span className="pro_tab_span" style={{ paddingRight: "0 px" }}>
                          <span className="filImg" title="filter" onClick={toggleFilterSection}>
                            <img src={filter} height={18} className="c_pointer" />
                          </span>
                          {showFilterSection && (
                            <>
                              <select
                                className="border border-0 fs_12 clr_flter"
                                value={selectedRoleId}
                                onChange={handleUserRole}
                              >
                                <option value="">Select Reviewed as</option>
                                {roleList &&
                                  roleList.map((role) => (
                                    <option key={role.id} value={role.id}>
                                      {role.name}
                                    </option>
                                  ))}
                              </select>
                              {selectedRoleId && <span className="clr_icn c_pointer" onClick={clear}>
                                <IoMdClose />
                              </span>}
                            </>
                          )}
                        </span>
                      }
                    ></Tab>
                    {avgRatng != 0 && <Tab className="str"
                      title={

                        <span className="d-flex align-items-center" style={{ fontSize: "12px", height: "24px" }}>
                          <span className="mar_ratng">
                            {Number(avgRatng).toFixed(1)}
                          </span>
                          <StarRating
                            className="avgRat"
                            rating={avgRatng}
                            disabled={true}
                            customClass="special-star"
                          />
                          <span className="mar_cnt" >({ratngCount})</span>

                        </span>
                      }
                    ></Tab>}
                  </Tabs>
                </div>
              </div>
              }
              <div className="me_revList">
                {!tiiptok
                  ? renderReviewCards(userreviewList)
                  : rederPostCards(postList)}
              </div>
            </Card>
          ) : (
            <div className="d-flex align-items-center justify-content-center shade">
              <Card className="shadow">
                <Card.Body style={{ padding: "20px" }} className="me_card">
                  The user's professional account is currently unavailable.
                  <br />
                  Would you like to see the user's personal account?&nbsp;
                  <a
                    className="mb2 card_txt name_blue c_pointer"
                    onClick={() => {
                      handleNameClick(userData.id, "Personal", null, null, "1");
                    }}
                  >
                    {userData.name}
                  </a>
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
        <Modal size="sm" show={isShare} onHide={() => setIsShare(false)}>
          <Modal.Header style={{ padding: "15px 0px!important" }}>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              Share
            </Modal.Title>
            <span
              onClick={() => {
                setIsShare(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="text-center p-2">
            <FacebookShareButton
              url={process.env.REACT_APP_URL}
              className="m-2"
            >
              <FacebookIcon size={32} />
            </FacebookShareButton>
            <TwitterShareButton
              url={process.env.REACT_APP_URL}
              title="tiipstr"
              quote={getSocialMediaShareInfo}
              hashtag={getSocialMediaHashTag}
              className="m-2"
            >
              <TwitterIcon size={32} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={process.env.REACT_APP_URL}
              title="tiipstr"
              quote={getSocialMediaShareInfo}
              hashtag={getSocialMediaHashTag}
              className="m-2"
            >
              <LinkedinIcon size={32} />
            </LinkedinShareButton>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          className="report_modal"
          show={showReport}
          onHide={closeReportModal}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Submit a report</h6>
            </Modal.Title>
            <span
              onClick={closeReportModal}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            <textarea
              className="form-control Comment_sty h_25"
              placeholder="Please add details about your report…"
              onChange={handleReport}
            ></textarea>
            {reportCommentErr && (
              <div className="text-danger">{reportCommentErr}</div>
            )}
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center mt-2 p-0"
            >
              <Button
                className="btn-warning font-weight-bold w-100"
                onClick={submitReport}
              >
                Submit
              </Button>
            </Col>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          className="report_modal"
          show={showReply}
          onHide={() => setShowReply(false)}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Review Comments</h6>
            </Modal.Title>
            <span
              onClick={() => {
                setShowReply(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            {revCmnt &&
              revCmnt.map((data) => (
                <Row>
                  <Col
                    className={`revCmtPop pb-0 ${showChildReply && replies.length != 0 ? "revcmt_bg" : ""
                      }`}
                  >
                    <div className="d-flex">
                      <div>
                        <img
                          className="brd_rad h_w_25"
                          src={
                            data.userImagePath ? data.userImagePath : profile
                          }
                        />
                      </div>
                      <div
                        className="d-flex"
                        style={{ marginLeft: "8px", alignItems: "center" }}
                      >
                        {data.userName}
                        <span className="dat_icn">
                          <GoDotFill />
                        </span>{" "}
                        <div style={{ fontSize: "11px", color: "#959595" }}>
                          {" "}
                          {moment
                            .utc(data.createdAt)
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        </div>
                      </div>
                    </div>
                    <div className="ctTxt">
                      {textWithLinks(data.commentText).map((word, index) => (
                        <span key={index}>{word} </span>
                      ))}
                    </div>
                    <div class="send d-flex gap_20 mb-2 mt-2">
                      <RatingBar
                        disabled={
                          reviewSts == "reported" ||
                            reviewSts == "blocked" ||
                            data.userId === props.userId
                            ? true
                            : false
                        }
                        msgreply={true}
                        liked={data.liked}
                        likecount={data.likes}
                        dislikecount={data.dislikes}
                        handlelike={() =>
                          handlerplylikechange(data.id, data.reviewId)
                        }
                        handledislike={() =>
                          handlerplydislikechange(data.id, data.reviewId)
                        }
                        handleRmvlike={() =>
                          rplylikecntDecrement(data.id, data.reviewId)
                        }
                        labelVAlue={data.score}
                      />
                      {reviewSts == "reported" ||
                        reviewSts == "blocked" ||
                        data.userId === props.userId ? (
                        <BiSolidCommentDetail className="cmt_icn clr_gray" />
                      ) : (
                        <BiSolidCommentDetail
                          className="cmt_icon c_pointer"
                          title="Add comment"
                          onClick={() => togglecomment(data.id)}
                        />
                      )}
                      <Row>
                        {replies.filter(
                          (obj) =>
                            obj.id == data.id || obj.parentCommentId == data.id
                        ).length != 0 && (
                            <span
                              className="rply c_pointer"
                              onClick={() =>
                                viewChildComments(replies, data.id, data.reviewId)
                              }
                            >
                              {replies.filter(
                                (obj) =>
                                  obj.id == data.id ||
                                  obj.parentCommentId == data.id
                              ).length === 1
                                ? `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Reply `
                                : `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Replies `}
                              <span>
                                {childcurrentIndex == data.id ? (
                                  <IoMdArrowDropup />
                                ) : (
                                  <IoMdArrowDropdown />
                                )}
                              </span>
                            </span>
                          )}
                      </Row>
                    </div>
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="position-relative p-0">
                          {cmtError && cmtErrId == data.id ? (
                            <div className="text-danger fz-12 ml-2">
                              {cmtError}
                            </div>
                          ) : (
                            ""
                          )}
                          <MentionsInput
                            style={mentionInputStyle}
                            className="form-control Cmt_box"
                            maxLength={288}
                            onChange={(e) => {
                              changeCmtText(e.target.value, "child");
                            }}
                            value={childComment}
                            placeholder="type someting..."
                          >
                            <Mention
                              style={{ backgroundColor: "#efebe3" }}
                              trigger="@"
                              data={users}
                            />
                          </MentionsInput>
                          {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                          <button
                            className="btn btn-primary cmt_btn"
                            onClick={() =>
                              postComment(data.id, data.reviewId, "child")
                            }
                          >
                            Post
                          </button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {childcurrentIndex == data.id && replies.length != 0
                      ? replies.map((item, index) =>
                        item.id == data.id ||
                          item.parentCommentId == data.id ? (
                          <Row>
                            <Col className="revchdcmt pb-0">
                              <div className="d-flex">
                                <div>
                                  <img
                                    className="brd_rad h_w_25"
                                    src={
                                      item.replyUserImagePath
                                        ? item.replyUserImagePath
                                        : profile
                                    }
                                  />
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    marginLeft: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.replyUserName}
                                  <span className="dat_icn">
                                    <GoDotFill />
                                  </span>{" "}
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {" "}
                                    {moment
                                      .utc(item.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </div>
                              <div className="ctTxt">
                                <span className="rep_men">
                                  {"@" + item.userName + " "}
                                </span>
                                {textWithLinks(item.replyCommentText).map(
                                  (word, index) => (
                                    <span key={index}>{word} </span>
                                  )
                                )}
                              </div>
                              <div class="send d-flex gap_20 mb-2 mt-2">
                                <RatingBar
                                  disabled={
                                    reviewSts == "reported" ||
                                      item.replyUserId === props.userId
                                      ? true
                                      : false
                                  }
                                  msgreply={true}
                                  liked={item.liked}
                                  likecount={item.likes}
                                  dislikecount={item.dislikes}
                                  handlelike={() =>
                                    handlerplylikechange(
                                      item.replyCommentId,
                                      data.reviewId
                                    )
                                  }
                                  handledislike={() =>
                                    handlerplydislikechange(
                                      item.replyCommentId,
                                      data.reviewId
                                    )
                                  }
                                  handleRmvlike={() =>
                                    rplylikecntDecrement(
                                      item.replyCommentId,
                                      data.reviewId
                                    )
                                  }
                                  labelVAlue={item.score}
                                />
                                {reviewSts == "reported" ||
                                  reviewSts == "blocked" ||
                                  item.replyUserId === props.userId ? (
                                  <BiSolidCommentDetail className="cmt_icn clr_gray" />
                                ) : (
                                  <BiSolidCommentDetail
                                    className="cmt_icon c_pointer"
                                    title="Add comment"
                                    onClick={() =>
                                      togglechildcomment(item.replyCommentId)
                                    }
                                  />
                                )}
                              </div>
                              {childrepplycurrentIndex ==
                                item.replyCommentId ? (
                                <Row>
                                  <div className="position-relative p-0">
                                    {cmtError &&
                                      cmtErrId == item.replyCommentId ? (
                                      <div className="text-danger ml-2 fz-12">
                                        {cmtError}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <MentionsInput
                                      style={mentionInputStyle}
                                      className="form-control Cmt_box"
                                      maxLength={288}
                                      onChange={(e) => {
                                        changeCmtText(
                                          e.target.value,
                                          "child"
                                        );
                                      }}
                                      value={childComment}
                                      placeholder="type someting..."
                                    >
                                      <Mention
                                        style={{ backgroundColor: "#efebe3" }}
                                        trigger="@"
                                        data={users}
                                      />
                                    </MentionsInput>
                                    {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                    <button
                                      className="btn btn-primary cmt_btn"
                                      onClick={() =>
                                        postComment(
                                          item.replyCommentId,
                                          data.reviewId,
                                          "child"
                                        )
                                      }
                                    >
                                      Post
                                    </button>
                                  </div>
                                </Row>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )
                      )
                      : ""}
                  </Col>
                </Row>
              ))}
          </Modal.Body>
        </Modal>
        <CustomModal show={showBusnScore}
          handleClose={() => setShowBusnScore(false)}
          post={true}
          title="Business scores"
          body={
            <>
              <BusinessScores
                businessScore={userData.business?.showBusinessScore}
                cultureScore={userData.business?.showCultureScore}
                peopleScore={userData.business?.showPeopleScore}
                userData={userData}
                loadChart={() => profileChartInfo()}
                setScoreType={setScoreType}
                scoreType={scoreType}
              />
            </>
          } />
        {/* <Modal size="sm" show={showIcons} onHide={() => setShowIcons(false)}>
          <Modal.Body className="p-3">
            {userData.facebookUrl && (
              <a
                href={userData.facebookUrl}
                target="_blank"
                className="a_social"
              >
                <AiFillFacebook
                  className="c_pointer social_icon_f mr-2"
                  size={28}
                />
              </a>
            )}
            {userData.linkedinUrl && (
              <a
                href={userData.linkedinUrl}
                target="_blank"
                className="a_social"
              >
                <FaLinkedin size={25} className="social_icon_li mr-2" />
              </a>
            )}
            {userData.instagramUrl && (
              <a
                href={userData.instagramUrl}
                target="_blank"
                className="a_social mr-2"
              >
                <InstagramIcon />
              </a>
            )}
            {userData.twitterUrl && (
              <a
                href={userData.twitterUrl}
                target="_blank"
                className="a_social"
              >
                <FaSquareXTwitter
                  size={25}
                  className="rounded-0 social_icon mr-2"
                />
              </a>
            )}
            {userData.facebookUrl ||
            userData.linkedinUrl ||
            userData.instagramUrl ||
            userData.twitterUrl ? (
              <a
                href="https://setld.app/"
                target="_blank"
                className="a_social mr-2"
              >
                <img src={setldImg} height={22} width={22} />
              </a>
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
    isKycVerified: state.user.isKycVerified,
    imagePath: state.user.imagePath,
  };
};
export default connect(mapStateToProps)(Viewuser);