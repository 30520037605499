import React, { useEffect, useState } from 'react';
import HeaderMenu from '../headerMenu';
import SideBar from '../SideBar';
import { Card, Col, Row, Modal, Form, Button, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import BreadCrumb from '../../plugins/Breadcrumb';
import { connect, useSelector } from 'react-redux';
import loader from "../../../assets/images/loader.gif";
import { AiOutlineClose } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import { useToast } from "../../Toast";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import store from "../../../assets/images/store.png";
import { IoIosCloseCircle } from "react-icons/io";

const MarketPlaceTab = ({ userId }) => {
  const toast = useToast();
  const [id, setId] = useState(null);
  const handleClose = () => setDeletePopupShow(false);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [marketPlaceItems, setMarketPlaceItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newItem, setNewItem] = useState({
    title: '',
    description: '',
    price: '',
    quantity: '',
    file: null,
    category: '',
    fileName: '',
    fileName: '',
  });
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoryid, setCategoryid] = useState('');
  const [selectedCategory, setSelectedItem] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [subActiveKey, setSubActiveKey] = useState("common_market");

  useEffect(() => {
    fetchMarketPlaceItems();
    fetchCategories(); // Fetch categories for dropdown
  }, []);

  const fetchMarketPlaceItems = async () => {
    try {
      const response = await window.apiService.getMarketplaceItems();
      setMarketPlaceItems(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching marketplace items:', error);
      setMarketPlaceItems([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await window.apiService.getCategories();
      setCategories(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    console.log("Close Modal")
    setShowModal(false);
    setErrors({});
    setNewItem({
      title: '',
      description: '',
      price: '',
      quantity: '',
      file: null,
      category: '',
      fileName: '',
      filePath: ''
    });
    setIsEditMode(false);
    setCurrentItemId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prevState) => ({
      ...prevState,
      [name]: value
    }));

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const handleFileChange = (e) => {
    setNewItem((prevState) => ({
      ...prevState,
      file: e.target.files[0]
    }));

    // Clear error when user selects a file
    if (errors.file) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: ''
      }));
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!newItem.title) formErrors.title = "Title is required.";
    if (!newItem.description) formErrors.description = "Description is required.";
    if (!newItem.price) formErrors.price = "Price is required.";
    if (!newItem.quantity) formErrors.quantity = "Quantity is required.";
    if (!newItem.file && !isEditMode) formErrors.file = "File is required.";
    if (!newItem.category) formErrors.category = "Category is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {

      setSubmitting(true);
      // Step 1: Add marketplace item
      const itemData = {
        title: newItem.title,
        description: newItem.description,
        price: newItem.price,
        quantity: newItem.quantity,
        userId: userId,
        sellerId: userId, // Add sellerId here
        createdBy: userId, // Add createdBy here
        categoryId: categoryid,
        // Assuming backend expects categoryId
      };


      let response;

      if (isEditMode) {
        await window.apiService.updateMarketplaceItem(currentItemId, itemData);
        console.log("Item updated successfully");

        // Step 2: Upload file if selected during edit mode
        if (newItem.file) {
          const formData = new FormData();
          formData.append('file', newItem.file);
          formData.append('id', currentItemId); // Use the current item ID for the edit mode
          formData.append('sellerId', userId); // Default sellerId as 1

          await window.apiService.uploadMarketplaceFile(formData);
          console.log("File uploaded successfully");
        }
      } else {



        const response = await window.apiService.addMarketplaceItem(itemData);
        console.log("Item added successfully:", response);



        // Step 2: Upload file if selected
        if (newItem.file) {
          const formData = new FormData();
          formData.append('file', newItem.file);
          formData.append('id', response.data.id); // Assuming response contains the new item ID
          formData.append('sellerId', userId); // Default sellerId as 1

          await window.apiService.uploadMarketplaceFile(formData);
          console.log("File uploaded successfully");
        }
      }
      // Refresh marketplace items
      fetchMarketPlaceItems();
      handleCloseModal();

      // Clear form fields
      setNewItem({
        title: '',
        description: '',
        price: '',
        quantity: '',
        file: null,
        category: '',
        fileName: '',
        filePath: ''
      });
    } catch (error) {
      console.error(isEditMode ? 'Error updating item:' : 'Error adding new item:', error);
    } finally {
      setSubmitting(false); // Finish submitting process
    }
  };

  const renderCategoryDropdown = () => {
    return (
      <Dropdown >
        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{ width: '400px', height: '35px', border: '1px solid #d3d0d0', textAlign: 'start' }}>
          {newItem.category ? newItem.category : 'Select Category'}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ width: '400px' }}>
          {categories.map((category) => (
            <Dropdown.Item key={category.id}
              onClick={() => {
                setNewItem({ ...newItem, category: category.name });
                setCategoryid(category.id);
              }}
            >
              {category.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.substring(0, maxLength)}...`;
  };

  const renderTextWithTooltip = (text, maxLength) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-top">{text}</Tooltip>}
      >
        <span>{truncateText(text, maxLength)}</span>
      </OverlayTrigger>
    );
  };

  const handleEditItem = (item) => {
    setIsEditMode(true);
    setCurrentItemId(item.id);
    setNewItem({
      title: item.title,
      description: item.description,
      price: item.price,
      quantity: item.quantity,
      file: null, // File input is always null when editing
      category: categories.find(category => category.id === item.categoryId)?.name || '',
      fileName: item.fileName,
      filePath: item.filePath,
    });
    setCategoryid(item.categoryId);
    setShowModal(true);
  };
  const showDeletePopup = (id) => {
    setId(id);
    setDeletePopupShow(true);
  };
  const handleDeleteItem = async () => {
    if (id) {
      try {
        const response = await window.apiService.deleteMarketplaceItem(id);
        if (response.status === "OK") {
          toast.success("Deleted successfully.");
          fetchMarketPlaceItems();
          setDeletePopupShow(false);
        } else {
          toast.error(response.message);
        }
        // Refresh marketplace items
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  // Styles and components for marketplace item display
  const imageStyle = {
    width: '100%',
    height: '190px',
    objectFit: 'scale-down',
    backgroundColor: '#f0f0f0',
    padding: '5px'
  };

  const cardStyle = {
    backgroundColor: '#c0c0c0',
    border: '1px solid blue',
    height: 'auto',
    marginBottom: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  };

  const headingStyle = {
    marginTop: '28px',
    marginBottom: '0.2rem',
    marginLeft: '40px',
    fontWeight: '500',
    lineHeight: '1.2',
    color: 'black',
    fontSize: '1.25rem'
  };

  const cardBodyStyle = {
    padding: '10px'
  };

  const buttonstyle = {
    cursor: 'pointer',
    marginRight: '42px',
    marginBottom: '-30px'
  };

  const submitButtonStyle = {
    marginTop: '14px',
    height: '30px',
    fontSize: '15px'
  };

  const customcol = {
    flex: '0 0 19%',
    maxWidth: '18%',
    marginLeft: '1%',
    marginRight: '1%'
  };
  const renderCards = (items) => (
    items.length === 0 ? (
      <div className="row col-md-12 d-align mx-0">
        <div className="no-data w-100 text-center font-weight-600 fz_14" style={{ marginTop: '-400px' }}>
          Sorry, there is no data to be displayed
        </div>
      </div>
    ) : (
      <Row className='col-12 mt-5'>
        {items.map((item, index) => (
          <Col key={index} sm={12} md={6} lg={4} xl={2} style={customcol}>
            <Card className="market-card">
              <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                {item.createdBy === userId && (
                  <>
                    <FiEdit
                      title="Edit"
                      style={{
                        color: "#144689",
                        fontSize: "17px",
                        marginRight: "4px",
                        cursor: "pointer"
                      }}
                      onClick={() => handleEditItem(item)}
                    />
                    <HiOutlineTrash
                      onClick={() => showDeletePopup(item.id)}
                      title="Delete"
                      style={{
                        color: "red",
                        fontSize: "17px",
                        cursor: "pointer"
                      }}
                    />
                  </>
                )}
              </div>
              <Card.Img
                variant="top"
                src={item.filePath}
                style={imageStyle}
              />
              <Card.Body style={cardBodyStyle}>
                <div>{renderTextWithTooltip(item.title, 15)}</div>
                <div>{renderTextWithTooltip(item.description, 20)}</div>
                <div>Price: ${item.price}</div>
                <div>Quantity: {item.quantity}</div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    )
  );

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          <BreadCrumb />
          <div className="pro_tab_div">
            <Tabs
              defaultActiveKey="common_market"
              activeKey={subActiveKey}
              onSelect={(key) => setSubActiveKey(key)}
              id="marketplace_tab"
              className="mb-2"
            >

              <Tab
                eventKey="common_market"
                title={
                  <span className="pro_tab_span">

                    <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                      <img
                        src={store}
                        className="dashboardTabIcon"
                      />
                    </span>
                    Marketplace
                  </span>
                }
              >
                <Card className="shade h-500 card text-center d-flex align-items-center text-primary">
                  <div className="d-flex justify-content-between align-items-center w-100"></div>
                  {loading ? (
                    <div className="text-center">
                      <img className="loader-image" src={loader} alt="Loading..." />
                    </div>
                  ) : marketPlaceItems.length === 0 ? (
                    <div className="row col-md-12 d-align mx-0">
                      <div className="no-data w-100 text-center font-weight-600 fz_14" style={{ marginTop: '-400px' }}>
                        Sorry, there is no data to be displayed
                      </div>
                    </div>
                  ) : (
                    renderCards(marketPlaceItems.filter(item => item.createdBy !== userId))
                  )}
                </Card>

              </Tab>
              <Tab
                eventKey="our_market"
                title={
                  <span className="pro_tab_span">
                    <BiAddToQueue className="dashboardTabIcon" />
                    My Products
                  </span>
                }
              >
                <Card className="shade h-500 card text-center d-flex align-items-center text-primary">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h1 style={headingStyle}></h1>
                    {!showModal ? (
                      <Button
                        variant="primary"
                        className="btn-sm"
                        onClick={handleShowModal}
                        style={buttonstyle}
                      >
                        Add Item
                      </Button>
                    ) : (
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={handleCloseModal}
                        style={buttonstyle}
                      >
                        Close
                      </Button>
                    )}
                  </div>
                  {loading ? (
                    <div className="text-center">
                      <img className="loader-image" src={loader} alt="Loading..." />
                    </div>
                  ) : marketPlaceItems.length === 0 ? (
                    <div className="row col-md-12 d-align mx-0">
                      <div className="no-data w-100 text-center font-weight-600 fz_14" style={{ marginTop: '-400px' }}>
                        Sorry, there is no data to be displayed
                      </div>
                    </div>
                  ) : (
                    renderCards(marketPlaceItems.filter(item => item.createdBy === userId))
                  )}
                </Card>

              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      <Modal size='sm' show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title style={{ 'fontSize': '15px', 'fontWeight': '600' }}>{isEditMode ? 'Edit Item' : 'Add Item'}</Modal.Title>

          <span onClick={handleCloseModal} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body style={{ marginTop: "-20px" }}>
          <Form onSubmit={handleFormSubmit} style={{ marginTop: "-20px" }}>
            <Form.Group controlId="formCategory">

              <div>
                <Form.Label className='pt-2' style={{ fontSize: '12px' }}>Category</Form.Label>

              </div>
              <div className='w-100'>
                {renderCategoryDropdown()}

              </div>

              <Form.Control.Feedback type="invalid">
                {errors.category}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTitle">
              <Form.Label style={{ fontSize: '12px' }}>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={newItem.title}
                onChange={handleInputChange}
                isInvalid={!!errors.title}
                style={{ height: '30px' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label style={{ fontSize: '12px' }}>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={newItem.description}
                onChange={handleInputChange}
                isInvalid={!!errors.description}
                style={{ height: '30px' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label style={{ fontSize: '12px' }}>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={newItem.price}
                onChange={handleInputChange}
                isInvalid={!!errors.price}
                style={{ height: '30px' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.price}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formQuantity">
              <Form.Label style={{ fontSize: '12px' }}>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                value={newItem.quantity}
                onChange={handleInputChange}
                isInvalid={!!errors.quantity}
                style={{ height: '30px' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label style={{ fontSize: '12px' }}>File</Form.Label>
              <Form.Control
                type="file"
                name="file"
                onChange={handleFileChange}
                isInvalid={!!errors.file}
                style={{ height: '30px', fontSize: '12px' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.file}
              </Form.Control.Feedback><div className='d-flex'>
                {isEditMode && <div className="col-6 mar_img preview_ttle">
                  <a
                    href={
                      newItem.filePath
                    }
                    target="_blank"
                    title={
                      newItem.fileName
                    }
                    className="fz-10"
                  >
                    {
                      newItem.fileName
                    }
                  </a>

                </div>}
                {/* <div className='mar-10 c_pointer'> <IoIosCloseCircle className="clr_red" /></div> */}
              </div>
            </Form.Group>
            <div className='text-end'>
              <Button className="btn-sm" variant="primary" type="submit" style={submitButtonStyle} disabled={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure to delete?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <Button
            variant="primary"
            className="del_bttn"
            onClick={handleDeleteItem}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setDeletePopupShow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId
});

export default connect(mapStateToProps)(MarketPlaceTab);
