import StarRating from "./StarRating";
import { IoIosArrowDown } from "react-icons/io";
import { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Horizontalbar from "../../plugins/Horizontalbar";
import { FiUsers, FiUser } from "react-icons/fi";
import { GrCircleQuestion } from "react-icons/gr";
const BusinessScores = (props) => {
  const targetRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [score, setScore] = useState(null);
  const [culturalScoreRating, setCulturalScoreRating] = useState([]);
  const [peopleScoreRating, setPeopleScoreRating] = useState([]);
  const [busnScoreRating, setBusnScoreRating] = useState([]);
  const handleButtonClick = (score, rating) => {
    setScore(score);
    setShowPopover(!showPopover);
  };
  const formatStarRevList = (revs) => {
    const starData = revs[0];
    const labelValueMap = {};
    for (const label in starData) {
      labelValueMap[label] = parseFloat(starData[label]);
    }
    const sortedLabels = Object.keys(labelValueMap).sort((a, b) => {
      const numA = parseInt(a.split(" ")[0]);
      const numB = parseInt(b.split(" ")[0]);
      return numB - numA;
    });
    const result = sortedLabels.map((label) => ({
      label,
      value: labelValueMap[label],
    }));
    return result;
  };
  const handleBusnScoreRating = (reviews) => {
    const result = formatStarRevList(reviews);
    console.log("result", result);
    if (reviews.length > 0) setBusnScoreRating(result);
    else setBusnScoreRating([]);
  };
  const handleCulturalScoreRating = (reviews) => {
    const result = formatStarRevList(reviews);
    if (reviews.length > 0) setCulturalScoreRating(result);
    else setCulturalScoreRating([]);
  };
  const handlePeopleScoreRating = (reviews) => {
    const result = formatStarRevList(reviews);
    if (reviews.length > 0) setPeopleScoreRating(result);
    else setPeopleScoreRating([]);
  };
  const handleOverlayClose = () => {
    setShowPopover(false);
  };

  return (
    <>
      <Col
        sm={12}
        md={12}
        lg={12}
        xl={1}
        className={`p-0 ${props.userData.userType === "Business" ? "score_div" : ""
          }`}
      >
        <div className="d-flex flex-column">
          {props.businessScore && <div
            className={`rounded-0 mb-2 p-1 hb ${props.scoreType === "BS" ? "hb_bor" : ""
              } c_pointer`}
            onClick={() => props.setScoreType("BS")}
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <p className="mb-0 fz_13 font-weight-400 text-center">
              Business Score{" "}
              <GrCircleQuestion
                className=" c_pointer font-weight-500 text-blue fz_13 mr-2"
                title="Average of reviews given for products and services."
              />
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <span className="fz_14">
                {props.userData.business.businessScore != 0 && props.userData.business.businessScore != null
                  ? props.userData.business.businessScore.toFixed(1)
                  : ""}
              </span>
              <span>
                <StarRating
                  rating={props.userData.business.businessScore}
                  disabled={true}
                  isReview={true}
                />
              </span>
              <span className="fz_14">
                {"(" + props.userData.business.businessScoreCount + ")"}
              </span>
              <span className="tooltip">
                <span
                  onMouseEnter={() =>
                    handleBusnScoreRating(
                      props.userData.business.businessScoreRating
                    )
                  }
                >
                  <IoIosArrowDown />
                </span>
                {busnScoreRating.length > 0 && (
                  <div className="tooltiptext busn_tooltip">
                    {busnScoreRating.map((item, index) => (
                      <Row key={index}>
                        <Col sm={12} md={3} lg={3} xl={2} className="p-0">
                          <span className="prog_span">{item.label}</span>{" "}
                        </Col>
                        <Col sm={12} md={8} lg={8} xl={7} className="p-0">
                          <Horizontalbar dataSet={item.value} />
                        </Col>
                        <Col
                          sm={12}
                          md={3}
                          lg={3}
                          xl={2}
                          className="p-0 prog_span"
                          style={{ marginLeft: "10px" }}
                        >
                          <span>
                            {item.value}
                            {item.value > 1 ? (
                              <FiUsers style={{ marginLeft: "6px" }} />
                            ) : (
                              <FiUser style={{ marginLeft: "6px" }} />
                            )}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </span>
            </div>
          </div>}
          {props.peopleScore && <div
            className={`rounded-0 mb-2 p-1 hb ${props.scoreType === "PS" ? "hb_bor" : ""
              } c_pointer`}
            onClick={() => props.setScoreType("PS")}
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <p className="mb-0 fz_13 font-weight-400 text-center">
              People Score{" "}
              <GrCircleQuestion
                className=" c_pointer font-weight-500 text-blue fz_13 mr-2"
                title="Average of combined Personal scores of all
                  employees."
              />
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <span className="fz_14">
                {props.userData.business.peopleScore != 0 && props.userData.business.peopleScore != null
                  ? props.userData.business.peopleScore.toFixed(1)
                  : ""}
              </span>
              <span>
                <StarRating
                  rating={props.userData.business.peopleScore}
                  disabled={true}
                  isReview={true}
                />
              </span>
              <span className="fz_14">
                {"(" + props.userData.business.peopleScoreCount + ")"}
              </span>
              <span className="tooltip">
                <span
                  onMouseEnter={() =>
                    handlePeopleScoreRating(
                      props.userData.business.peopleScoreRating
                    )
                  }
                >
                  <IoIosArrowDown />
                </span>
                {peopleScoreRating.length > 0 && (
                  <div className="tooltiptext busn_tooltip">
                    {peopleScoreRating.map((item, index) => (
                      <Row key={index}>
                        <Col sm={12} md={3} lg={3} xl={2} className="p-0">
                          <span className="prog_span">{item.label}</span>{" "}
                        </Col>
                        <Col sm={12} md={8} lg={8} xl={7} className="p-0">
                          <Horizontalbar dataSet={item.value} />
                        </Col>
                        <Col
                          sm={12}
                          md={3}
                          lg={3}
                          xl={2}
                          className="p-0 prog_span"
                          style={{ marginLeft: "10px" }}
                        >
                          <span>
                            {item.value}
                            {item.value > 1 ? (
                              <FiUsers style={{ marginLeft: "6px" }} />
                            ) : (
                              <FiUser style={{ marginLeft: "6px" }} />
                            )}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </span>
            </div>
          </div>}
          {props.cultureScore && <div
            className={`rounded-0 mb-2 p-1 hb ${props.scoreType === "CS" ? "hb_bor" : ""
              } c_pointer`}
            onClick={() => props.setScoreType("CS")}
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <p className="mb-0 fz_13 font-weight-400 text-center">
              Culture Score{" "}
              <GrCircleQuestion
                className=" c_pointer font-weight-500 text-blue fz_13 mr-2"
                title="Average score of the company by its employees."
              />
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <span className="fz_14">
                {props.userData.business.cultureScore != 0 && props.userData.business.cultureScore != null
                  ? props.userData.business.cultureScore.toFixed(1)
                  : ""}
              </span>
              <span>
                <StarRating
                  rating={props.userData.business.cultureScore}
                  disabled={true}
                  isReview={true}
                />
              </span>
              <span className="fz_14">
                {"(" + props.userData.business.culturalScoreCount + ")"}
              </span>
              <span className="tooltip">
                <span
                  onMouseEnter={() =>
                    handleCulturalScoreRating(
                      props.userData.business.culturalScoreRating
                    )
                  }
                >
                  {/* <IoIosArrowDown /> */}
                </span>
                {culturalScoreRating.length > 0 && (
                  <div className="tooltiptext busn_tooltip">
                    {culturalScoreRating.map((item, index) => (
                      <Row key={index}>
                        <Col sm={12} md={3} lg={3} xl={2} className="p-0">
                          <span className="prog_span">{item.label}</span>{" "}
                        </Col>
                        <Col sm={12} md={8} lg={8} xl={7} className="p-0">
                          <Horizontalbar dataSet={item.value} />
                        </Col>
                        <Col
                          sm={12}
                          md={3}
                          lg={3}
                          xl={2}
                          className="p-0 prog_span"
                          style={{ marginLeft: "10px" }}
                        >
                          <span>
                            {item.value}
                            {item.value > 1 ? (
                              <FiUsers style={{ marginLeft: "6px" }} />
                            ) : (
                              <FiUser style={{ marginLeft: "6px" }} />
                            )}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </span>
            </div>
          </div>}
        </div>
      </Col>
    </>
  );
};

export default BusinessScores;
