import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Button, Card, Col, Row, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useToast } from "../../Toast";
import profile from "../../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { GoDotFill } from "react-icons/go";
import moment from "moment";
import { BiSolidCommentDetail } from "react-icons/bi";
import { followId } from "../../../Redux/Actions/userAction";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { MdReviews } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RatingBar from "../../plugins/RatingBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import mentionInputStyle from "../../../assets/css/MentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import { commonUtils } from "../../CommonUtils";
const ViewNotification = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();
  const notifytype = location.state.type;
  const revStatus = location.state.status;
  const revId = location.state.revId;
  const [revCmt, setRevCmt] = useState([]);
  const [replies, setRevReplies] = useState([]);
  const [togglecmt, settoggleCmt] = useState();
  const [currentIndex, setcurrentIndex] = useState(null);
  const [comment, setComment] = useState("");
  const [childrepplycurrentIndex, setchildreplycurrentIndex] = useState([]);
  const [showChildReply, setShowChildReply] = useState(false);
  const [childComment, setchildComment] = useState("");
  const [childcurrentIndex, setchildcurrentIndex] = useState(null);
  const [childcmtrplycurrentIndex, setchildcmtrplycurrentIndex] =
    useState(null);
  const [cmtError, setCmtError] = useState("");
  const [reviewSts, setReviewSts] = useState("");
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [users, setUsers] = useState([]);
  const [cmtErrId, setCmtErrId] = useState(null);
  const [parentToggle, setParentToggle] = useState(false);
  const [reviewInfo, setRevInfo] = useState("");
  const [cmtInfo, setCmtInfo] = useState("");
  const [cmtReplies, setCmtReplies] = useState("");
  const [parentcmtId, setParentcmtId] = useState(null);
  const userId = useSelector((state) => state.user.userId);
  const navigate = useNavigate();
  const loadRevComments = async (revId) => {
    try {
      const response = await window.apiService.reviewCmtList({ id: revId });
      setRevCmt(response.data.recordInfo.reviewComments);
      setRevReplies(response.data.recordInfo.reviewReplies);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const loadCommentReply = async (cmtId) => {
    try {
      const response = await window.apiService.cmtReplyList(cmtId);
      setCmtReplies(response.data.recordInfo);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const viewComments = (sts) => {
    settoggleCmt(!togglecmt);
    setParentToggle(false);
    setReviewSts(sts);
  };
  const postCommentReply = async (id, parentId, revId) => {
    if (comment.trim().length !== 0 || childComment.trim().length !== 0) {
      let params = {
        userId: userId,
        commentText: childComment,
        reviewId: revId,
        parentCommentId: id,
        mentionUserIds: mentionuserIds,
        professionalId: props.profileId ? props.profileId : undefined,
      };
      try {
        const response = await window.apiService.addComment(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setchildComment("");
          loadCommentReply(parentId);
          setchildreplycurrentIndex(null);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    } else {
      setCmtError("Comment text is required");
      setCmtErrId(id);
    }
  };
  const postComment = async (id, revId, type) => {
    if (comment.trim().length !== 0 || childComment.trim().length !== 0) {
      let params = "";
      if (type == "child") {
        params = {
          userId: userId,
          commentText: childComment,
          reviewId: revId,
          parentCommentId: id,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      } else {
        params = {
          userId: userId,
          commentText: comment,
          reviewId: id,
          parentCommentId: null,
          mentionUserIds: mentionuserIds,
          professionalId:
            props.userType == "Professional" ? props.profileId : null,
          businessId: props.userType == "Business" ? props.profileId : null,
        };
      }
      try {
        const response = await window.apiService.addComment(params);
        console.log("response", response);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setComment("");
          setchildComment("");
          togglecomment(response.data.id);
          setchildreplycurrentIndex(null);
          setParentToggle(false);
          setmentionuserIds([]);
          if (notifytype == "review") {
            loadRevComments(params.reviewId);
            loadRevInfo(params.reviewId);
          } else loadCommentReply(id);
          console.log("yt", notifytype);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    } else {
      setCmtError("Comment text is required");
      setCmtErrId(id);
    }
  };
  const userLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != props.userId &&
          row.userId.split("-")[0] != props.userId &&
          row.userId.split("&")[0] != props.userId
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };
  const changeCmtText = (value, type) => {
    commonUtils.changeCmtText(
      value,
      type,
      setmentionuserIds,
      setComment,
      setchildComment
    );
  };
  const toggleparentcomment = () => {
    setComment("");
    settoggleCmt(false);
    setParentToggle(!parentToggle);
    setchildcurrentIndex(null);
    setShowChildReply(false);
  };
  const togglecomment = (id) => {
    setComment("");
    setchildcurrentIndex(null);
    setShowChildReply(false);
    if (currentIndex == id) {
      setcurrentIndex(null);
    } else {
      setcurrentIndex(id);
    }
  };
  const viewChildComments = (data, id, revId) => {
    setcurrentIndex(null);
    setShowChildReply(!showChildReply);
    if (childcurrentIndex == id) {
      setchildcurrentIndex(null);
    } else {
      setchildcurrentIndex(id);
    }
    if (notifytype == "review") loadRevComments(revId);
    else loadCommentReply(id);
  };
  const viewChildCommentsReply = (id) => {
    if (childcmtrplycurrentIndex == id) {
      setchildcmtrplycurrentIndex(null);
    } else {
      setchildcmtrplycurrentIndex(id);
    }
    setchildreplycurrentIndex(null);
  };
  const likecntDecrement = (id) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: -1 });
  };
  const updateLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addLike(likeParams);
      if (response.data.status == "OK") {
        loadRevInfo(likeParams.reviewId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handlelikechange = (id) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 1 });
  };
  const handledislikechange = (id) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 0 });
  };
  const rplylikecntDecrement = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: userId,
      liked: -1,
    });
  };
  const handlerplylikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: userId,
      liked: 1,
    });
  };
  const handlerplydislikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: userId,
      liked: 0,
    });
  };
  const updaterplyLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addCommentLike(likeParams);
      if (response.data.status == "OK") {
        if (notifytype == "review") loadRevComments(likeParams.reviewId);
        else loadCmtInfo(likeParams.commentId);
        loadCommentReply(likeParams.commentId);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const rplycmtlikecntDecrement = (id, revId, cmtId) => {
    setParentcmtId(cmtId);
    updaterplycmtLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: userId,
      liked: -1,
    });
  };
  const handlerplycmtlikechange = (id, revId, cmtId) => {
    setParentcmtId(cmtId);
    updaterplycmtLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: userId,
      liked: 1,
    });
  };
  const handlerplycmtdislikechange = (id, revId, cmtId) => {
    setParentcmtId(cmtId);
    updaterplycmtLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: userId,
      liked: 0,
    });
  };
  const updaterplycmtLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addCommentLike(likeParams);
      if (response.data.status == "OK") {
        loadCommentReply(parentcmtId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const togglechildcomment = (id) => {
    setchildComment("");
    if (childrepplycurrentIndex == id) {
      setchildreplycurrentIndex(null);
    } else {
      setchildreplycurrentIndex(id);
    }
    setchildcmtrplycurrentIndex(null);
  };
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let userId = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [userId, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [userId, profId] = userIdPart.split("-");
      }
      if (name && userId) {
        if (userId !== props.userId) {
          dispatch(followId(userId));
          navigate("/dashboard/viewuser", {
            state: {
              userId,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const handleNameClick = (userId) => {
    dispatch(followId(userId));
    navigate("/dashboard/viewuser", { state: { userId: userId } });
  };
  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word;
    });
  }
  const loadRevInfo = async (id) => {
    try {
      const response = await window.apiService.viewReview(id);
      if (response.data.status == "ACCEPTED") {
        setRevInfo(response.data.recordInfo);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const loadCmtInfo = async (id) => {
    try {
      const response = await window.apiService.cmtReview(id);
      if (response.data.status == "ACCEPTED") {
        setCmtInfo(response.data.recordInfo);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  useEffect(() => {
    userLists();
    if (notifytype == "review") {
      loadRevInfo(revId);
      loadRevComments(revId);
    } else {
      loadCmtInfo(revId);
      loadCommentReply(revId);
    }
  }, [revId]);
  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        <BreadCrumb />
        <Card className="shade h-500">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="shadow mt_10">
                  {notifytype == "review" ? (
                    <Card.Body className="p-0">
                      <Col sm={12} md={12} lg={12} xl={12}>
                        <div className="d-flex m-2">
                          <div className="m-2">
                            <MdReviews className="rev_not_icn" />
                          </div>
                          <div className="m-2">
                            <h6>Review Notification</h6>
                          </div>
                        </div>
                        <div className="d-flex m-3 flex-column">
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 fz_14 font-weight-500">
                              New mention added
                            </p>
                          </div>
                          <Card className="mt-2 p-2 rounded-0">
                            <div className="d-flex">
                              <Col sm={12} md={12} lg={1} xl={1}>
                                <img
                                  src={
                                    reviewInfo.reviewerImagePath
                                      ? reviewInfo.reviewerImagePath
                                      : profile
                                  }
                                  className="rounded-circle"
                                  height={40}
                                  width={40}
                                />
                              </Col>
                              <Col sm={12} md={12} lg={11} xl={11}>
                                <div className="d-flex">
                                  <h6
                                    className="follUser"
                                    style={{ marginBottom: "0" }}
                                    onClick={() =>
                                      handleNameClick(reviewInfo.userId)
                                    }
                                  >
                                    {reviewInfo.reviewerName}
                                  </h6>
                                  <span className="dat_icn">
                                    <GoDotFill />
                                  </span>
                                  <div
                                    className="mt_2x"
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {" "}
                                    {moment
                                      .utc(reviewInfo.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                                {reviewInfo.comment ? (
                                  <div className="">
                                    {textWithLinks(reviewInfo.comment).map(
                                      (word, index) => (
                                        <span className="fz_14" key={index}>
                                          {word}{" "}
                                        </span>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </div>
                            <div class="send d-flex gap_20 mb-2 mt-2">
                              <RatingBar
                                disabled={
                                  reviewInfo.status === "reported"
                                    ? true
                                    : false
                                }
                                msgreply={true}
                                liked={reviewInfo.liked}
                                likecount={reviewInfo.likes}
                                dislikecount={reviewInfo.dislikes}
                                handlelike={() => handlelikechange(revId)}
                                handledislike={() => handledislikechange(revId)}
                                handleRmvlike={() => likecntDecrement(revId)}
                                labelVAlue={reviewInfo.score}
                              />
                              {reviewInfo.status === "reported" ? (
                                <BiSolidCommentDetail
                                  className="cmt_icon clr_gray"
                                  title="Add comment"
                                />
                              ) : (
                                <BiSolidCommentDetail
                                  className="cmt_icon c_pointer"
                                  title="Add comment"
                                  onClick={() => toggleparentcomment()}
                                />
                              )}
                              <Row>
                                {revCmt.length != 0 && (
                                  <span
                                    className="rply c_pointer"
                                    onClick={() =>
                                      viewComments(reviewInfo.status)
                                    }
                                  >
                                    {revCmt.length === 1
                                      ? `${revCmt.length} Reply `
                                      : `${revCmt.length} Replies `}
                                    <span>
                                      {togglecmt ? (
                                        <IoMdArrowDropup />
                                      ) : (
                                        <IoMdArrowDropdown />
                                      )}
                                    </span>
                                  </span>
                                )}
                              </Row>
                            </div>
                            {parentToggle ? (
                              <div className="position-relative p-0 justify-content-center">
                                <Col sm={12} md={12} lg={9} xl={9}>
                                  {cmtError && cmtErrId == revId ? (
                                    <div className="text-danger fz-12 ml-2">
                                      {cmtError}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <MentionsInput
                                    style={mentionInputStyle}
                                    className="form-control Cmt_box"
                                    maxLength={288}
                                    onChange={(e) => {
                                      changeCmtText(e.target.value, "parent");
                                    }}
                                    value={comment}
                                    placeholder="type someting..."
                                  >
                                    <Mention
                                      style={{ backgroundColor: "#efebe3" }}
                                      trigger="@"
                                      data={users}
                                    />
                                  </MentionsInput>
                                  <button
                                    className="btn btn-primary cmt_btn mr-2"
                                    onClick={() =>
                                      postComment(revId, 0, "parent")
                                    }
                                  >
                                    Post
                                  </button>
                                </Col>
                              </div>
                            ) : (
                              ""
                            )}
                          </Card>
                          {togglecmt ? (
                            <div className="noti_re_cmt">
                              <Card className="rounded-0 p-2 noti_re_cmt">
                                {revCmt &&
                                  revCmt.map((data) => (
                                    <div className="d-flex m-2 br_1px">
                                      <Col
                                        className={`pb-0 ${showChildReply && replies.length != 0
                                          ? "revcmt_bg"
                                          : ""
                                          }`}
                                      >
                                        <div className="d-flex">
                                          <div>
                                            <img
                                              className="brd_rad h_w_25"
                                              src={
                                                data.userImagePath
                                                  ? data.userImagePath
                                                  : profile
                                              }
                                            />
                                          </div>
                                          <div
                                            className="d-flex"
                                            style={{
                                              marginLeft: "8px",
                                              alignItems: "center",
                                            }}
                                          >
                                            {data.userName}
                                            <span className="dat_icn">
                                              <GoDotFill />
                                            </span>{" "}
                                            <div
                                              style={{
                                                fontSize: "11px",
                                                color: "#959595",
                                              }}
                                            >
                                              {" "}
                                              {moment
                                                .utc(data.createdAt)
                                                .local()
                                                .startOf("seconds")
                                                .fromNow()}
                                            </div>
                                          </div>
                                        </div>
                                        {data.commentText ? (
                                          <div className="ctTxt">
                                            {textWithLinks(
                                              data.commentText
                                            ).map((word, index) => (
                                              <span
                                                className="fz_14"
                                                key={index}
                                              >
                                                {word}{" "}
                                              </span>
                                            ))}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div class="send d-flex gap_20 mb-2 mt-2">
                                          <RatingBar
                                            disabled={
                                              reviewSts === "reported" ||
                                                data.userId === userId
                                                ? true
                                                : false
                                            }
                                            msgreply={true}
                                            liked={data.liked}
                                            likecount={data.likes}
                                            dislikecount={data.dislikes}
                                            handlelike={() =>
                                              handlerplylikechange(
                                                data.id,
                                                data.reviewId
                                              )
                                            }
                                            handledislike={() =>
                                              handlerplydislikechange(
                                                data.id,
                                                data.reviewId
                                              )
                                            }
                                            handleRmvlike={() =>
                                              rplylikecntDecrement(
                                                data.id,
                                                data.reviewId
                                              )
                                            }
                                            labelVAlue={data.score}
                                          />
                                          {reviewSts === "reported" ||
                                            data.userId === userId ? (
                                            <BiSolidCommentDetail
                                              className="cmt_icon clr_gray"
                                              title="Add comment"
                                            />
                                          ) : (
                                            <BiSolidCommentDetail
                                              className="cmt_icon c_pointer"
                                              title="Add comment"
                                              onClick={() =>
                                                togglecomment(data.id)
                                              }
                                            />
                                          )}
                                          <Row>
                                            {replies.filter(
                                              (obj) =>
                                                obj.id == data.id ||
                                                obj.parentCommentId == data.id
                                            ).length != 0 && (
                                                <span
                                                  className="rply c_pointer"
                                                  onClick={() =>
                                                    viewChildComments(
                                                      replies,
                                                      data.id,
                                                      data.reviewId
                                                    )
                                                  }
                                                >
                                                  {replies.filter(
                                                    (obj) =>
                                                      obj.id == data.id ||
                                                      obj.parentCommentId ==
                                                      data.id
                                                  ).length === 1
                                                    ? `${replies.filter(
                                                      (obj) =>
                                                        obj.id == data.id ||
                                                        obj.parentCommentId ==
                                                        data.id
                                                    ).length
                                                    } Reply `
                                                    : `${replies.filter(
                                                      (obj) =>
                                                        obj.id == data.id ||
                                                        obj.parentCommentId ==
                                                        data.id
                                                    ).length
                                                    } Replies `}
                                                  <span>
                                                    {childcurrentIndex ==
                                                      data.id ? (
                                                      <IoMdArrowDropup />
                                                    ) : (
                                                      <IoMdArrowDropdown />
                                                    )}
                                                  </span>
                                                </span>
                                              )}
                                          </Row>
                                        </div>
                                        {currentIndex == data.id ? (
                                          <Row>
                                            <div className="position-relative p-0">
                                              {cmtError &&
                                                cmtErrId == data.id ? (
                                                <div className="text-danger fz-12 ml-2">
                                                  {cmtError}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <MentionsInput
                                                style={mentionInputStyle}
                                                className="form-control Cmt_box"
                                                maxLength={288}
                                                onChange={(e) => {
                                                  changeCmtText(
                                                    e.target.value,
                                                    "child"
                                                  );
                                                }}
                                                value={childComment}
                                                placeholder="type someting..."
                                              >
                                                <Mention
                                                  style={{
                                                    backgroundColor: "#efebe3",
                                                  }}
                                                  trigger="@"
                                                  data={users}
                                                />
                                              </MentionsInput>
                                              <button
                                                className="btn btn-primary cmt_btn"
                                                onClick={() =>
                                                  postComment(
                                                    data.id,
                                                    data.reviewId,
                                                    "child"
                                                  )
                                                }
                                              >
                                                Post
                                              </button>
                                            </div>
                                          </Row>
                                        ) : (
                                          ""
                                        )}
                                        {childcurrentIndex == data.id &&
                                          replies.length != 0
                                          ? replies.map((item, index) =>
                                            item.id == data.id ||
                                              item.parentCommentId ==
                                              data.id ? (
                                              <Row>
                                                <Col className="revchdcmt pb-0">
                                                  <div className="d-flex">
                                                    <div>
                                                      <img
                                                        className="brd_rad h_w_25"
                                                        src={
                                                          item.replyUserImagePath
                                                            ? item.replyUserImagePath
                                                            : profile
                                                        }
                                                      />
                                                    </div>
                                                    <div
                                                      className="d-flex"
                                                      style={{
                                                        marginLeft: "8px",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      {item.replyUserName}
                                                      <span className="dat_icn">
                                                        <GoDotFill />
                                                      </span>{" "}
                                                      <div
                                                        style={{
                                                          fontSize: "11px",
                                                          color: "#959595",
                                                        }}
                                                      >
                                                        {" "}
                                                        {moment
                                                          .utc(item.createdAt)
                                                          .local()
                                                          .startOf("seconds")
                                                          .fromNow()}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="ctTxt">
                                                    <span className="rep_men">
                                                      {"@" +
                                                        item.userName +
                                                        " "}
                                                    </span>
                                                    {item.replyCommentText
                                                      ? textWithLinks(
                                                        item.replyCommentText
                                                      ).map(
                                                        (word, index) => (
                                                          <span
                                                            className="fz_14"
                                                            key={index}
                                                          >
                                                            {word}{" "}
                                                          </span>
                                                        )
                                                      )
                                                      : ""}
                                                  </div>
                                                  <div class="send d-flex gap_20 mb-2 mt-2">
                                                    <RatingBar
                                                      disabled={
                                                        reviewSts ===
                                                          "reported" ||
                                                          item.replyUserId ===
                                                          userId
                                                          ? true
                                                          : false
                                                      }
                                                      msgreply={true}
                                                      liked={item.liked}
                                                      likecount={item.likes}
                                                      dislikecount={
                                                        item.dislikes
                                                      }
                                                      handlelike={() =>
                                                        handlerplylikechange(
                                                          item.replyCommentId,
                                                          data.reviewId
                                                        )
                                                      }
                                                      handledislike={() =>
                                                        handlerplydislikechange(
                                                          item.replyCommentId,
                                                          data.reviewId
                                                        )
                                                      }
                                                      handleRmvlike={() =>
                                                        rplylikecntDecrement(
                                                          item.replyCommentId,
                                                          data.reviewId
                                                        )
                                                      }
                                                      labelVAlue={item.score}
                                                    />
                                                    {reviewSts ===
                                                      "reported" ||
                                                      item.replyUserId ===
                                                      userId ? (
                                                      <BiSolidCommentDetail
                                                        className="cmt_icon clr_gray"
                                                        title="Add comment"
                                                      />
                                                    ) : (
                                                      <BiSolidCommentDetail
                                                        className="cmt_icon c_pointer"
                                                        title="Add comment"
                                                        onClick={() =>
                                                          togglechildcomment(
                                                            item.id
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  {childrepplycurrentIndex ==
                                                    item.replyCommentId ? (
                                                    <Row>
                                                      <div className="position-relative p-0">
                                                        {cmtError &&
                                                          cmtErrId ==
                                                          item.replyCommentId ? (
                                                          <div className="text-danger ml-2 fz-12">
                                                            {cmtError}
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                        <MentionsInput
                                                          style={
                                                            mentionInputStyle
                                                          }
                                                          className="form-control Cmt_box"
                                                          maxLength={288}
                                                          onChange={(e) => {
                                                            changeCmtText(
                                                              e.target.value,
                                                              "child"
                                                            );
                                                          }}
                                                          value={childComment}
                                                          placeholder="type someting..."
                                                        >
                                                          <Mention
                                                            style={{
                                                              backgroundColor:
                                                                "#efebe3",
                                                            }}
                                                            trigger="@"
                                                            data={users}
                                                          />
                                                        </MentionsInput>
                                                        <button
                                                          className="btn btn-primary cmt_btn"
                                                          onClick={() =>
                                                            postComment(
                                                              item.replyCommentId,
                                                              data.reviewId,
                                                              "child"
                                                            )
                                                          }
                                                        >
                                                          Post
                                                        </button>
                                                      </div>
                                                    </Row>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Col>
                                              </Row>
                                            ) : (
                                              ""
                                            )
                                          )
                                          : ""}
                                      </Col>
                                    </div>
                                  ))}
                              </Card>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Card.Body>
                  ) : (
                    <Card.Body className="p-0">
                      <Col sm={12} md={12} lg={12} xl={12}>
                        <div className="d-flex m-2">
                          <div className="m-2">
                            <MdReviews className="rev_not_icn" />
                          </div>
                          <div className="m-2">
                            <h6>Comment Notification</h6>
                          </div>
                        </div>
                        {cmtInfo && (
                          <div className="d-flex m-3 flex-column">
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 fz_14 font-weight-500">
                                New comment mention added
                              </p>
                            </div>
                            <div className="">
                              <Card className="rounded-0 p-2">
                                <div className="d-flex">
                                  <Col
                                    className={`pb-0 ${showChildReply && cmtReplies.length != 0
                                      ? "revcmt_bg"
                                      : ""
                                      }`}
                                  >
                                    <div className="d-flex">
                                      <div>
                                        <img
                                          className="brd_rad h_w_25"
                                          src={
                                            cmtInfo.user.imagePath
                                              ? cmtInfo.user.imagePath
                                              : profile
                                          }
                                        />
                                      </div>
                                      <div
                                        className="d-flex"
                                        style={{
                                          marginLeft: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        {cmtInfo.user.name}
                                        <span className="dat_icn">
                                          <GoDotFill />
                                        </span>{" "}
                                        <div
                                          style={{
                                            fontSize: "11px",
                                            color: "#959595",
                                          }}
                                        >
                                          {" "}
                                          {moment
                                            .utc(cmtInfo.createdAt)
                                            .local()
                                            .startOf("seconds")
                                            .fromNow()}
                                        </div>
                                      </div>
                                    </div>
                                    {cmtInfo.commentText ? (
                                      <div className="ctTxt">
                                        {textWithLinks(cmtInfo.commentText).map(
                                          (word, index) => (
                                            <span className="fz_14" key={index}>
                                              {word}{" "}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div class="send d-flex gap_20 mb-2 mt-2">
                                      <RatingBar
                                        disabled={
                                          revStatus === "reported" ||
                                            cmtInfo.userId ===
                                            cmtInfo.loggedInUserId
                                            ? true
                                            : false
                                        }
                                        msgreply={true}
                                        liked={cmtInfo.liked}
                                        likecount={cmtInfo.likes}
                                        dislikecount={cmtInfo.dislikes}
                                        handlelike={() =>
                                          handlerplylikechange(
                                            cmtInfo.id,
                                            cmtInfo.reviewId
                                          )
                                        }
                                        handledislike={() =>
                                          handlerplydislikechange(
                                            cmtInfo.id,
                                            cmtInfo.reviewId
                                          )
                                        }
                                        handleRmvlike={() =>
                                          rplylikecntDecrement(
                                            cmtInfo.id,
                                            cmtInfo.reviewId
                                          )
                                        }
                                        labelVAlue={cmtInfo.score}
                                      />
                                      {revStatus === "reported" ||
                                        cmtInfo.userId ===
                                        cmtInfo.loggedInUserId ? (
                                        <BiSolidCommentDetail
                                          className="cmt_icon clr_gray"
                                          title="Add comment"
                                        />
                                      ) : (
                                        <BiSolidCommentDetail
                                          className="cmt_icon c_pointer"
                                          title="Add comment"
                                          onClick={() =>
                                            togglecomment(cmtInfo.id)
                                          }
                                        />
                                      )}
                                      <Row>
                                        {cmtReplies.length > 0
                                          ? cmtReplies.filter(
                                            (obj) =>
                                              obj.id == cmtInfo.id ||
                                              obj.parentCommentId ==
                                              cmtInfo.id
                                          ).length != 0 && (
                                            <span
                                              className="rply c_pointer"
                                              onClick={() =>
                                                viewChildComments(
                                                  cmtReplies,
                                                  cmtInfo.id,
                                                  cmtInfo.reviewId
                                                )
                                              }
                                            >
                                              {cmtReplies.filter(
                                                (obj) =>
                                                  obj.id == cmtInfo.id ||
                                                  obj.parentCommentId ==
                                                  cmtInfo.id
                                              ).length === 1
                                                ? `${cmtReplies.filter(
                                                  (obj) =>
                                                    obj.id ==
                                                    cmtInfo.id ||
                                                    obj.parentCommentId ==
                                                    cmtInfo.id
                                                ).length
                                                } Reply `
                                                : `${cmtReplies.filter(
                                                  (obj) =>
                                                    obj.id ==
                                                    cmtInfo.id ||
                                                    obj.parentCommentId ==
                                                    cmtInfo.id
                                                ).length
                                                } Replies `}
                                              <span>
                                                {childcurrentIndex ==
                                                  cmtInfo.id ? (
                                                  <IoMdArrowDropup />
                                                ) : (
                                                  <IoMdArrowDropdown />
                                                )}
                                              </span>
                                            </span>
                                          )
                                          : ""}
                                      </Row>
                                    </div>
                                    {currentIndex == cmtInfo.id ? (
                                      <Row>
                                        <div className="position-relative p-0">
                                          {cmtError &&
                                            cmtErrId == cmtInfo.id ? (
                                            <div className="text-danger fz-12 ml-2">
                                              {cmtError}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <MentionsInput
                                            style={mentionInputStyle}
                                            className="form-control Cmt_box"
                                            maxLength={288}
                                            onChange={(e) => {
                                              changeCmtText(
                                                e.target.value,
                                                "child"
                                              );
                                            }}
                                            value={childComment}
                                            placeholder="type someting..."
                                          >
                                            <Mention
                                              style={{
                                                backgroundColor: "#efebe3",
                                              }}
                                              trigger="@"
                                              data={users}
                                            />
                                          </MentionsInput>
                                          <button
                                            className="btn btn-primary cmt_btn"
                                            onClick={() =>
                                              postComment(
                                                cmtInfo.id,
                                                cmtInfo.reviewId,
                                                "child"
                                              )
                                            }
                                          >
                                            Post
                                          </button>
                                        </div>
                                      </Row>
                                    ) : (
                                      ""
                                    )}
                                    {childcurrentIndex == cmtInfo.id &&
                                      cmtReplies.length != 0
                                      ? cmtReplies.map((item, index) =>
                                        item.id == cmtInfo.id ||
                                          item.parentCommentId == cmtInfo.id ? (
                                          <Row>
                                            <Col className="revchdcmt pb-0">
                                              <div className="d-flex">
                                                <div>
                                                  <img
                                                    className="brd_rad h_w_25"
                                                    src={
                                                      item.userImagePath
                                                        ? item.userImagePath
                                                        : profile
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    marginLeft: "8px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {item.userName}
                                                  <span className="dat_icn">
                                                    <GoDotFill />
                                                  </span>{" "}
                                                  <div
                                                    style={{
                                                      fontSize: "11px",
                                                      color: "#959595",
                                                    }}
                                                  >
                                                    {" "}
                                                    {moment
                                                      .utc(item.createdAt)
                                                      .local()
                                                      .startOf("seconds")
                                                      .fromNow()}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="ctTxt">
                                                {item.commentText
                                                  ? textWithLinks(
                                                    item.commentText
                                                  ).map((word, index) => (
                                                    <span
                                                      className="fz_14"
                                                      key={index}
                                                    >
                                                      {word}{" "}
                                                    </span>
                                                  ))
                                                  : ""}
                                              </div>
                                              <div class="send d-flex gap_20 mb-2 mt-2">
                                                <RatingBar
                                                  disabled={
                                                    revStatus ===
                                                      "reported" ||
                                                      item.userId === userId
                                                      ? true
                                                      : false
                                                  }
                                                  msgreply={true}
                                                  liked={item.liked}
                                                  likecount={item.likes}
                                                  dislikecount={item.dislikes}
                                                  handlelike={() =>
                                                    handlerplycmtlikechange(
                                                      item.id,
                                                      cmtInfo.reviewId,
                                                      item.parentCommentId
                                                    )
                                                  }
                                                  handledislike={() =>
                                                    handlerplycmtdislikechange(
                                                      item.id,
                                                      cmtInfo.reviewId,
                                                      item.parentCommentId
                                                    )
                                                  }
                                                  handleRmvlike={() =>
                                                    rplycmtlikecntDecrement(
                                                      item.id,
                                                      cmtInfo.reviewId,
                                                      item.parentCommentId
                                                    )
                                                  }
                                                  labelVAlue={item.score}
                                                />
                                                {revStatus === "reported" ||
                                                  item.userId === userId ? (
                                                  <BiSolidCommentDetail
                                                    className="cmt_icon clr_gray"
                                                    title="Add comment"
                                                  />
                                                ) : (
                                                  <BiSolidCommentDetail
                                                    className="cmt_icon c_pointer"
                                                    title="Add comment"
                                                    onClick={() =>
                                                      togglechildcomment(
                                                        item.id
                                                      )
                                                    }
                                                  />
                                                )}
                                                <Row>
                                                  {item.commentReplies
                                                    .length > 0
                                                    ? item.commentReplies.filter(
                                                      (obj) =>
                                                        obj.id == item.id ||
                                                        obj.parentCommentId ==
                                                        item.id
                                                    ).length != 0 && (
                                                      <span
                                                        className="rply c_pointer"
                                                        onClick={() =>
                                                          viewChildCommentsReply(
                                                            item.id,
                                                            item.reviewId
                                                          )
                                                        }
                                                      >
                                                        {item.commentReplies.filter(
                                                          (obj) =>
                                                            obj.id ==
                                                            item.id ||
                                                            obj.parentCommentId ==
                                                            item.id
                                                        ).length === 1
                                                          ? `${item.commentReplies.filter(
                                                            (obj) =>
                                                              obj.id ==
                                                              item.id ||
                                                              obj.parentCommentId ==
                                                              item.id
                                                          ).length
                                                          } Reply `
                                                          : `${item.commentReplies.filter(
                                                            (obj) =>
                                                              obj.id ==
                                                              item.id ||
                                                              obj.parentCommentId ==
                                                              item.id
                                                          ).length
                                                          } Replies `}
                                                        <span>
                                                          {childcmtrplycurrentIndex ==
                                                            item.id ? (
                                                            <IoMdArrowDropup />
                                                          ) : (
                                                            <IoMdArrowDropdown />
                                                          )}
                                                        </span>
                                                      </span>
                                                    )
                                                    : ""}
                                                </Row>
                                              </div>
                                              {childrepplycurrentIndex ==
                                                item.id ? (
                                                <Row>
                                                  <div className="position-relative p-0">
                                                    {cmtError &&
                                                      cmtErrId == item.id ? (
                                                      <div className="text-danger ml-2 fz-12">
                                                        {cmtError}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <MentionsInput
                                                      style={
                                                        mentionInputStyle
                                                      }
                                                      className="form-control Cmt_box"
                                                      maxLength={288}
                                                      onChange={(e) => {
                                                        changeCmtText(
                                                          e.target.value,
                                                          "child"
                                                        );
                                                      }}
                                                      value={childComment}
                                                      placeholder="type someting..."
                                                    >
                                                      <Mention
                                                        style={{
                                                          backgroundColor:
                                                            "#efebe3",
                                                        }}
                                                        trigger="@"
                                                        data={users}
                                                      />
                                                    </MentionsInput>
                                                    <button
                                                      className="btn btn-primary cmt_btn"
                                                      onClick={() =>
                                                        postCommentReply(
                                                          item.id,
                                                          item.parentCommentId,
                                                          item.reviewId
                                                        )
                                                      }
                                                    >
                                                      Post
                                                    </button>
                                                  </div>
                                                </Row>
                                              ) : (
                                                ""
                                              )}
                                            </Col>
                                            {childcmtrplycurrentIndex ==
                                              item.id &&
                                              item.commentReplies.length !=
                                              0 ? (
                                              <Card className="notify_cmt_box">
                                                {childcmtrplycurrentIndex ==
                                                  item.id &&
                                                  item.commentReplies.length !=
                                                  0
                                                  ? item.commentReplies.map(
                                                    (reply, index) =>
                                                      reply.id ==
                                                        cmtInfo.id ||
                                                        reply.parentCommentId ==
                                                        cmtInfo.id ? (
                                                        <Row>
                                                          <Col className="revchdcmt pb-0">
                                                            <div className="d-flex">
                                                              <div>
                                                                <img
                                                                  className="brd_rad h_w_25"
                                                                  src={
                                                                    reply.replyUserImagePath
                                                                      ? reply.replyUserImagePath
                                                                      : profile
                                                                  }
                                                                />
                                                              </div>
                                                              <div
                                                                className="d-flex"
                                                                style={{
                                                                  marginLeft:
                                                                    "8px",
                                                                  alignItems:
                                                                    "center",
                                                                }}
                                                              >
                                                                {
                                                                  reply.replyUserName
                                                                }
                                                                <span className="dat_icn">
                                                                  <GoDotFill />
                                                                </span>{" "}
                                                                <div
                                                                  style={{
                                                                    fontSize:
                                                                      "11px",
                                                                    color:
                                                                      "#959595",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  {moment
                                                                    .utc(
                                                                      reply.createdAt
                                                                    )
                                                                    .local()
                                                                    .startOf(
                                                                      "seconds"
                                                                    )
                                                                    .fromNow()}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="ctTxt">
                                                              <span className="rep_men">
                                                                {"@" +
                                                                  reply.userName +
                                                                  " "}
                                                              </span>
                                                              {reply.replyCommentText
                                                                ? textWithLinks(
                                                                  reply.replyCommentText
                                                                ).map(
                                                                  (
                                                                    word,
                                                                    index
                                                                  ) => (
                                                                    <span
                                                                      className="fz_14"
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      {
                                                                        word
                                                                      }{" "}
                                                                    </span>
                                                                  )
                                                                )
                                                                : ""}
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                      ) : (
                                                        ""
                                                      )
                                                  )
                                                  : ""}
                                              </Card>
                                            ) : (
                                              ""
                                            )}
                                          </Row>
                                        ) : (
                                          ""
                                        )
                                      )
                                      : ""}
                                  </Col>
                                </div>
                              </Card>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    revId: state.user.id,
  };
};
export default connect(mapStateToProps)(ViewNotification);
