import React from "react";
import "../Register/register.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToast } from "../Toast";
import { Form, InputGroup } from "react-bootstrap";
import Header from "../../layout/header";
import { useState } from "react";
import LoaderOverlay from "../plugins/LoaderOverlay";
import { GrMail } from "react-icons/gr";
import { Button, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { userAction } from "../../Redux/Actions/userAction";
import ResetMessage from "./ResetMessage";
import { commonUtils } from "../CommonUtils";
const Login = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [showMsg, setShowMsg] = useState(false);
  const toast = useToast();
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await window.apiService.resetPassword(data);
      setIsLoading(false);
      if (response.data.status == "OK") {
        setShowMsg(true);
        dispatch(userAction(response.data.data));
      } else {
        if (response.data.message == "Invalid Password.") {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error Occured");
    }
  };
  return (
    <>
      {!showMsg && <LoaderOverlay isLoading={isLoading} />}
      {!showMsg && (
        <div className="body-content">
          <div className="">
            <Col lg="12" md="12" sm="12" xl="12">
              <Row>
                <Col lg="5" md="5" sm="12" xl="5" className="log_bg log_text">
                  {commonUtils.LeftDiv()}
                </Col>
                <Col
                  lg="7"
                  md="7"
                  sm="12"
                  xl="7"
                  style={{ backgroundColor: "white" }}
                >
                  <Header />
                  <div className="d-flex justify-content-center  align-items-center copyright bg-content">
                    <Col lg="7" md="7" sm="12" xl="5">
                      <div className="mt-2">
                        <h4>Forgot Password</h4>
                      </div>
                      <div style={{ paddingTop: "0px!important" }}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <Form.Group
                            controlId="email"
                            className="log_form form-group"
                          >
                            <div className="inputContainer">
                              <label className="fz_14">Email</label>
                              <InputGroup
                                className={` ${errors.email ? "is-invalid" : "inputBox"
                                  }`}
                              >
                                <InputGroup.Text className="border-0">
                                  <GrMail />
                                </InputGroup.Text>
                                <Form.Control
                                  className="formControl fz_13"
                                  type="text"
                                  placeholder="Email"
                                  name="email"
                                  {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                />
                              </InputGroup>
                              {errors.email && (
                                <Form.Text className="text-danger ">
                                  {errors.email.message}
                                </Form.Text>
                              )}
                            </div>
                          </Form.Group>
                          <div className="text-center">
                            <div style={{ textAlign: "center" }}>
                              <Button
                                type="submit"
                                className="btn btn-primary res-btn"
                              >
                                {" "}
                                Reset Password
                              </Button>
                            </div>
                          </div>
                          <div className="rst_txt text-center">
                            <span>
                              If you have a valid tiipstr account, you will
                              receive instructions in your email for resetting
                              your password.
                            </span>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      )}
      {showMsg && <ResetMessage />}
    </>
  );
};

export default Login;
