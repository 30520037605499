import profile from "../../assets/images/profile.jpg";
import React, { useState, useEffect } from "react";
import { HiLocationMarker } from "react-icons/hi";
import StarRating from "../Dashboard/DashboardTabs/StarRating";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useToast } from "../Toast";
import { IoIosArrowDown } from "react-icons/io";
import { MdSwitchAccount } from "react-icons/md";
import { FiUser, FiUsers } from "react-icons/fi";
import Horizontalbar from "../../components/plugins/Horizontalbar";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import { useParams } from "react-router-dom";
import logo from "../../assets/images/tiipstr_lgo.svg";
import { HiBadgeCheck } from "react-icons/hi";

const QRCodeView = () => {
  const { token } = useParams();
  const [data, setData] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const toast = useToast();
  useEffect(() => {
    Decodetoken(token);
  }, [token]);

  const Decodetoken = async () => {
    if (token) {
      try {
        const response = await window.apiService.deCodeToken(token);
        viewReview(response.data.data.id, response.data.data.userType);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };

  const viewReview = async (id, userType) => {
    if (id) {
      const params = {
        id: id,
        userType: userType ? userType : "Personal",
      };
      try {
        const response = await window.apiService.userViewById(params);
        setData(response.data.data);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };

  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const result = starResponse.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
  };
  useEffect(() => {
    // Simulating data fetching
    setTimeout(() => {
      setData((prevData) => ({
        ...prevData,
        verified: true,
      }));
    }, 1000);
  }, []);
  return (
    <>
      <div className="qr_container">
        {" "}
        <div className="text-center">
          <img src={logo} height="90" />
        </div>
        <div className="qr_card">
          <div className="qr_pad">
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <img
                src={data.imagePath ? data.imagePath : profile}
                className="rounded-circle"
                width="60"
                height="60"
              />
            </div>
            <div className="mtp-12 text-center">
              <h5 className="mb-0 gradient-text">{data.name}{data.isKycVerified ? (
                <HiBadgeCheck
                  style={{
                    fontSize: "17px",
                    color: "green",
                    cursor: "pointer",
                    marginLeft: "5px"
                  }}
                  title="KYC Verified"
                  data-toggle="tooltip"
                  data-placement="top"
                />
              ) : (
                <></>
              )}</h5>
              <div className="fs_12 text-center">
                {data.userType ? <MdSwitchAccount /> : ""}&nbsp;
                {data.userType ? data.userType : ""}{" "}
              </div>
              <div className="fs_12 text-center">
                {data.location ? <HiLocationMarker /> : ""}&nbsp;
                {data.location ? data.location : "-"}{" "}
              </div>
              <div
                className="fs_12 d-flex align-items-center justify-content-center"
                style={{ height: "28px" }}
              >
                {data.averageReviewScore > 0.0 ? (
                  <label className="font-weight-bold mt_14">
                    {data.averageReviewScore.toFixed(1)}
                  </label>
                ) : (
                  ""
                )}
                <StarRating
                  rating={data.averageReviewCount}
                  disabled={true}
                  customClass="special-star"
                />
                <span>({data.totalReviewCount})</span>
                <OverlayTrigger
                  trigger="click"
                  key={data.id}
                  placement="bottom"
                  overlay={
                    <Popover id={`popover-${data.id}`} className="pop_rating">
                      <div className="drp_rating">
                        <StarRating rating={avgCount} disabled={true} />
                        <span>{avgCount} out of 5</span>{" "}
                      </div>
                      {revGlobalRtng.map((item, index) => (
                        <Row key={index}>
                          <Col
                            xs={3}
                            sm={12}
                            md={3}
                            lg={3}
                            xl={2}
                            className="p-0"
                          >
                            <span className="prog_span">{item.label}</span>{" "}
                          </Col>
                          <Col
                            xs={5}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={7}
                            className="p-0"
                          >
                            <Horizontalbar dataSet={item.value} />
                          </Col>
                          <Col
                            xs={3}
                            sm={12}
                            md={3}
                            lg={3}
                            xl={2}
                            className="p-0 prog_span"
                            style={{ marginLeft: "10px" }}
                          >
                            <span>
                              {item.value}
                              {item.value > 1 ? (
                                <FiUsers style={{ marginLeft: "6px" }} />
                              ) : (
                                <FiUser style={{ marginLeft: "6px" }} />
                              )}
                            </span>
                          </Col>
                        </Row>
                      ))}
                    </Popover>
                  }
                  rootClose
                >
                  <span
                    className="d-flex align-items-center"
                    onClick={() =>
                      handleArrowClick(data.starRating, data.averageReviewCount)
                    }
                  >
                    <IoIosArrowDown className="clr_gray" />
                  </span>
                </OverlayTrigger>
              </div>
              <div className="d-flex justify-content-between">
                <div className="stats">
                  <span>{data.followersCount}</span>
                  <h6>Followers</h6>
                </div>
                <div className="stats">
                  <span>{data.followingCount}</span>
                  <h6>Following</h6>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "0px 5px" }}>
            <hr className="gradient-line" />
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <p className="log_sgn">
              <a href="/Login">Login</a> here. Not registered?{" "}
              <a href="/registration">Sign up</a>!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodeView;
