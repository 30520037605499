

export const userAction = (data) => ({
  type: 'SAVE_USER_DATA',
  payload: {
    token: data.token,
    userId: data.userId,
    userType: data.userType,
    userName: data.name,
    imagePath: (data.imagePath != null ? data.imagePath : ''),
    newReviewCount: data.newReviewCount || 0,
    newInboxCount: data.newInboxCount || 0,
    groupNotify: data.groupNotify,
    newMentionCount: data.newMentionCount || 0,
    emailVerified: data.emailVerified,
    phoneVerified: data.phoneVerified,
    userTypeInfo: data.userTypeInfo,
    email: data.email,
    phone: (data.phone != undefined ? data.phone : ''),
    profileId: data.profileId,
    isKycVerified: data.isKycVerified
  }
});

export const logoutAction = () => ({
  type: 'LOGOUT',
});

// Admin

export const userTypeId = (selectedTypeId) => {
  return {
    type: 'USER_TYPE_ID',
    payload: {
      selectedTypeId,
    },
  };
};

export const followId = (followid, userType, profileId) => {
  return {
    type: 'FOLLOW_ID',
    payload: {
      followid,
      userType,
      profileId
    },
  };
};
export const notifyRevInfo = (revInfo) => ({
  type: 'NOT_REV_INFO',
  payload: {
    id: revInfo.id,
  }
});
export const revId = (revId) => {
  return {
    type: 'REV_ID',
    payload: {
      revId,
    },
  };
};
export const phoneNumber = (phNumber) => {
  return {
    type: 'PH_NUMBER',
    payload: {
      phNumber,
    },
  };
};
